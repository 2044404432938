import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed";

export interface ILayoutTexts extends ITextBase
{
    newBadgeHelper: string;
    newBadgeTitle: string;
    cookiesNotAccepted: string;
    cookieText: string;
    accept: string;
    decline: string;
    cookieTitle: string;
    createCompetence: string;
    createSkill: string;
    createOpportunity: string;
    createCertificate: string;
    edit: string;
    profile: string;
    settings: string;
    sales: string;
    pageHome: string;
    checking: string
    stage: string;
    currentStatus: string;
    scope: string;
    numberOfConnectedClients: string;
    browserInformations: string;
    possibleServiceWorkerStatuses: string;
    versionDialog: string;
    expandableText: string;
    search: string;
    contact: string
    imprint: string
    copyright: string
    copyrightShort: string
    employeeAndOEsLong: string
}
type ILangData = {
    [lang in ELanguage]: ILayoutTexts
}
export const layoutTexts: ILangData = {
    'th': {
        newBadgeTitle: 'ใหม่',
        newBadgeHelper: 'วัตถุนี้จะถูกสร้างใหม่เมื่อมีการบันทึก!',
        cookiesNotAccepted: 'ขออภัย ฟังก์ชันนี้ไม่สามารถใช้งานได้หากไม่ยอมรับคุกกี้',
        cookieText: 'เราใช้คุกกี้ที่จำเป็นทางเทคนิคบนเว็บไซต์ของเรา เพื่อการทำงานพื้นฐานที่จำเป็น โดยการกดปุ่ม “[ACCEPT]” คุณยินยอมให้ใช้คุกกี้เหล่านี้ กรุณาทราบว่าคุณสามารถถอนความยินยอมของคุณได้ทุกเวลา',
        cookieTitle: 'เว็บไซต์นี้ใช้คุกกี้',
        decline: 'ปฏิเสธ',
        accept: 'ยอมรับ',
        employeeAndOEsLong: 'คน',
        createCompetence: 'สร้างความสามารถ',
        createOpportunity: 'สร้างโอกาส',
        createSkill: 'สร้างทักษะ',
        createCertificate: 'สร้างใบรับรอง',
        edit: 'แก้ไข',
        profile: 'โปรไฟล์ของฉัน',
        settings: 'การตั้งค่า',
        sales: 'ฝ่ายขาย',
        pageHome: 'ไดเรกทอรีราก',
        copyrightShort: '© 2023 -',
        checking: 'กำลังตรวจสอบ...',
        stage: 'สภาพแวดล้อม',
        currentStatus: 'สถานะปัจจุบัน',
        scope: 'ขอบเขต',
        numberOfConnectedClients: 'จำนวนลูกค้าที่เชื่อมต่อ',
        browserInformations: 'ข้อมูลเบราว์เซอร์',
        possibleServiceWorkerStatuses: 'สถานะของ Service Worker ที่เป็นไปได้',
        versionDialog: 'ไดอะล็อกเวอร์ชัน',
        expandableText: 'คลิกเพื่อดูเนื้อหาทั้งหมด',
        oftenUsed: oftenUsedTranslations['th'],
        imprint: 'ข้อมูลบริษัท',
        contact: 'ติดต่อ',
        copyright: 'ลิขสิทธิ์ พ.ศ. 2566 - ',
        search: oftenUsedTranslations['th'].search,
    },
    'en': {
        newBadgeTitle: 'NEW',
        newBadgeHelper: 'This object will be recreated upon saving!',
        cookiesNotAccepted: 'Sorry, this feature cannot be used without accepting cookies.',
        cookieText: 'We only use technically necessary cookies on our website that are required for the basic operation. By pressing the “[ACCEPT]” button, you agree to the use of these cookies. Please note that you can withdraw your consent at any time.',
        cookieTitle: 'This website uses cookies.',
        decline: 'Decline',
        accept: 'Accept',
        employeeAndOEsLong: 'People',
        createCompetence: 'Create competence',
        createOpportunity: 'Create Opportunity',
        createSkill: 'Create skill',
        createCertificate: 'Create certificate',
        edit: 'Edit',
        profile: 'My Profile',
        settings: 'Settings',
        sales: 'Sales',
        pageHome: 'Root directory',
        copyrightShort: '© 2023 -',
        checking: 'Checking...',
        stage: 'Environment',
        currentStatus: 'Current status',
        scope: 'Scope',
        numberOfConnectedClients: 'Number of connected clients',
        browserInformations: 'Browser information',
        possibleServiceWorkerStatuses: 'Possible Service Worker statuses',
        versionDialog: 'Version dialog',
        expandableText: 'Click to show the full content',
        oftenUsed: oftenUsedTranslations['en'],
        imprint: 'Imprint',
        contact: 'Contact',
        copyright: 'Copyright 2023 - ',
        search: oftenUsedTranslations['en'].search,
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        imprint: 'Impressum',
        contact: 'Kontakt',
        copyright: 'Copyright 2023 - ',
        copyrightShort: '© 2023 - ',
        search: oftenUsedTranslations['de'].search,
        expandableText: 'Klicken um den ganzen Inhalt zu anzuzeigen',
        versionDialog: 'Versions-Dialog',
        possibleServiceWorkerStatuses: 'Mögliche Service Worker-Status',
        browserInformations: 'Browserinformationen',
        numberOfConnectedClients: 'Anzahl der verbundenen Clients',
        scope: 'Bereich',
        currentStatus: 'Aktueller Status',
        stage: 'Umgebung',
        checking: 'Überprüfen...',
        pageHome: 'Wurzelverzeichnis',
        sales: 'Vertrieb',
        employeeAndOEsLong: 'Menschen',
        settings: 'Einstellungen',
        profile: 'Mein Profil',
        edit: 'Bearbeiten',
        createCertificate: 'Zertifikat erstellen',
        createSkill: 'Fähigkeit erstellen',
        createOpportunity: 'Anfrage erstellen',
        createCompetence: 'Kompetenz erstellen',
        accept: 'Akzeptieren',
        decline: 'Ablehnen',
        cookieTitle: 'Diese Webseite verwendet Cookies.',
        cookieText: 'Wir setzen auf unserer Webseite lediglich technisch notwendige Cookies ein, die für den grundlegenden Betrieb erforderlich sind. Durch das Betätigen der Schaltfläche „[ACCEPT]“ stimmen Sie der Nutzung dieser Cookies zu. Bitte beachten Sie, dass Sie Ihre Zustimmung jederzeit widerrufen können.',
        cookiesNotAccepted: 'Tut uns leid, diese Funktion kann leider ohne die Verwendung von Cookies nicht benutzt werden.',
        newBadgeHelper: 'Dieses Objekt wird beim speichern neu erstellt!',
        newBadgeTitle: 'NEU',
    },
}