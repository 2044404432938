import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed"

export interface IAnalyseDialogTexts
{
    certificates: string;
    skills: string;
    roles: string;
    profileDescription: string;
    back: string;
    titleRoles: string;
    roleHelpText: string;
    suggestedRoles: string;
    price: string;
    certificateHelpText: string;
    skillHelpText: string;
    titleSkills: string;
    titleCertificates: string;
    titleSummary: string;
    titleAnoymize: string;
    skip: string;
    approve: string;
    character: string;
    aiConfirmation: string;
    initialText: string
    uploading: string
    extractingText: string
    fileAnalyze: string
    textAnalyze: string
    analyze: string
    waitForAi: string
    error: string
    next: string
    close: string
    explainationFile: string
    explainationText: string
    actualProfileDescription: string
    newProfileDescription: string
    updateEmployeeDescription: string
    suggestedCertificates: string
    newSuffix: string
    suggestedSkills: string
}
type ILangData = {
    [lang in ELanguage]: IAnalyseDialogTexts
}
export const analysedialogTexts: ILangData = {
    'th': {
        profileDescription: 'คำอธิบายโปรไฟล์',
        roleHelpText: 'มีการแยกบทบาทบางอย่างออกจากข้อความในโปรไฟล์ของคุณ คลิกที่สัญลักษณ์ "+" เพื่อเพิ่มลงในโปรไฟล์ของคุณ',
        suggestedRoles: 'บทบาทที่แนะนำ',
        titleRoles: 'เพิ่มบทบาทในโปรไฟล์ของคุณ',
        price: 'ราคา',
        skillHelpText: 'เลือกทักษะที่เหมาะสมกับคุณและให้คะแนน หลังจากนั้นคลิกที่สัญลักษณ์ "+" เพื่อเพิ่มลงในโปรไฟล์ของคุณ',
        certificateHelpText: 'เราได้สกัดบางใบรับรองจากข้อความในโปรไฟล์ของคุณ คลิกที่สัญลักษณ์ "+" เพื่อเพิ่มไปยังโปรไฟล์ของคุณ',
        titleSkills: 'ประเมินทักษะของคุณ',
        titleCertificates: 'เพิ่มใบรับรองลงในโปรไฟล์ของคุณ',
        titleSummary: 'เลือกใช้คำอธิบายโปรไฟล์ที่แนะนำ',
        titleAnoymize: 'ทำให้ข้อความโปรไฟล์ของคุณเป็นชื่อที่ไม่ระบุตัวตน',
        skip: 'ข้าม',
        approve: 'อนุมัติ',
        initialText: 'ฉันคือ ',
        uploading: 'กำลังอัปโหลด...',
        extractingText: 'กำลังแยกข้อความจากไฟล์...',
        fileAnalyze: "วิเคราะห์ไฟล์",
        textAnalyze: 'วิเคราะห์ข้อความ',
        analyze: 'วิเคราะห์',
        waitForAi: "กำลังรอ AI ประมวลผล...",
        explainationFile: 'คุณสามารถทำการทำนายอนุมานข้อความที่ได้จากไฟล์ได้ที่นี่ และส่งไปยัง AI เพื่อสกัดคำอธิบายโปรไฟล์ ทักษะ และใบรับรองของคุณ',
        explainationText: 'คุณสามารถส่งข้อความใด ๆ ไปยัง AI เพื่อสกัดคำอธิบายโปรไฟล์ ทักษะ และใบรับรองของคุณได้ที่นี่',
        actualProfileDescription: 'คำอธิบายโปรไฟล์ปัจจุบัน',
        newProfileDescription: 'คำอธิบายโปรไฟล์แนะนำ',
        updateEmployeeDescription: 'อัปเดตคำอธิบายของพนักงาน',
        suggestedCertificates: 'ใบรับรองแนะนำ',
        newSuffix: "[ใหม่]",
        suggestedSkills: 'ทักษะแนะนำ',
        aiConfirmation: `ฉันยืนยันว่าข้อความด้านบนได้รับการทำให้เป็นรายชื่อที่ไม่เปิดเผยและไม่มีข้อมูลส่วนบุคคลหรือข้อมูลลับใด ๆ ฉันยืนยันว่าข้อความนี้สามารถดำเนินการด้วย AI ได้`,
        character: 'อักขระที่ใช้ได้',
        error: oftenUsedTranslations['th'].error,
        next: oftenUsedTranslations['th'].next,
        close: oftenUsedTranslations['th'].close,
        back: oftenUsedTranslations['th'].back,
        roles: oftenUsedTranslations['th'].roles,
        skills: oftenUsedTranslations['th'].skills,
        certificates: oftenUsedTranslations['th'].certificates,
    },
    'en': {
        profileDescription: 'Profile Description',
        roleHelpText: 'Some roles have been extracted from your profile text. Click on the "+" symbol to add them to your profile.',
        suggestedRoles: 'Suggested Roles',
        titleRoles: 'Add roles to your profile',
        price: 'Price',
        skillHelpText: 'Choose the skills that suit you and rate them. Then click on the "+" symbol to add them to your profile.',
        certificateHelpText: 'We have extracted some certificates from your profile text. Click on the "+" symbol to add them to your profile.',
        titleSkills: 'Rate your skills',
        titleCertificates: 'Add certificates to your profile',
        titleSummary: 'Adopt a suggested profile description',
        titleAnoymize: 'Anonymize your profile text',
        skip: 'Skip',
        approve: 'Approve',
        initialText: 'I am ',
        uploading: 'Uploading...',
        extractingText: 'Extracting text from file...',
        fileAnalyze: "File-Analyze",
        textAnalyze: 'Text-Analyze',
        analyze: 'Analyze',
        waitForAi: "Waiting for AI processing...",
        explainationFile: 'You can anonymize the extracted text from the file here and then pass it to the AI for extracting your profile description, skills, and certificates.',
        explainationText: 'Here you can pass any text to the AI to extract a profile description, skills, and certificates.',
        actualProfileDescription: 'Actual profile description',
        newProfileDescription: 'Suggested profile description',
        updateEmployeeDescription: 'Update employee description',
        suggestedCertificates: 'Suggested certificates',
        newSuffix: "[NEW]",
        suggestedSkills: 'Suggested skills',
        aiConfirmation: `I confirm that the text above is anonymized and doesn't contain any personal or secret information. I also confirm that this text is allowed to be processed by AI.`,
        character: 'Available characters',
        error: oftenUsedTranslations['en'].error,
        next: oftenUsedTranslations['en'].next,
        close: oftenUsedTranslations['en'].close,
        back: oftenUsedTranslations['en'].back,
        roles: oftenUsedTranslations['en'].roles,
        skills: oftenUsedTranslations['en'].skills,
        certificates: oftenUsedTranslations['en'].certificates,
    },
    'de': {
        error: oftenUsedTranslations['de'].error,
        next: oftenUsedTranslations['de'].next,
        close: oftenUsedTranslations['de'].close,
        back: oftenUsedTranslations['de'].back,
        roles: oftenUsedTranslations['de'].roles,
        skills: oftenUsedTranslations['de'].skills,
        certificates: oftenUsedTranslations['de'].certificates,
        initialText: 'Ich bin \nIch kann \nIch mache ',
        uploading: 'Hochladen...',
        extractingText: 'Text aus Datei extrahieren...',
        fileAnalyze: "Datei-Analyse",
        textAnalyze: 'Text-Analyse',
        analyze: 'Analysieren',
        waitForAi: 'Text wird von der KI analysiert, bitte warten.',
        explainationFile: 'Du kannst den extrahierten Text aus der Datei hier anonymisieren und anschließend an die KI zur Extraktion deiner Beschreibung, Fähigkeiten und Zertifikate übergeben.',
        explainationText: 'Hier kannst du einen beliebigen Text an die KI übergeben um eine Profilbeschreibung, Skills und Zertifikate zu extrahieren.',
        actualProfileDescription: 'Aktuelle Profilbeschreibung',
        newProfileDescription: 'Vorgeschlagene Profilbeschreibung',
        updateEmployeeDescription: 'Profilbeschreibung aktuallisieren',
        suggestedCertificates: 'Vorgeschlagene Zertifikate',
        newSuffix: '[NEU]',
        suggestedSkills: 'Vorgeschlagene Fähigkeiten',
        aiConfirmation: `Ich bestätige, dass der obige Text anonymisiert ist und keine persönlichen oder geheimen Informationen enthält. Ebenso bestätige ich, dass dieser Text durch KI verarbeitet werden darf.`,
        character: 'Zur Verfügung stehende Zeichen',
        approve: 'Freigeben',
        skip: 'Überspringen',
        titleAnoymize: 'Anonymisiere deinen Profiltext',
        titleSummary: 'Übernehme eine vorgeschlagene Profilbeschreibung',
        titleCertificates: 'Füge Zertifikate zu deinem Profil hinzu',
        titleSkills: 'Bewerte deine Fähigkeiten',
        certificateHelpText: 'Es wurden einige Zertifikate aus deinem Profiltext extrahiert. Klicke auf das "+"-Symbol, um sie deinem Profil hinzuzufügen.',
        skillHelpText: 'Wähle die Fähigkeiten aus, die zu dir passen, und bewerte diese. Klicke danach auf das "+"-Symbol, um sie deinem Profil hinzuzufügen.',
        price: 'Kosten',
        titleRoles: 'Füge Rollen deinem Profil hinzu',
        suggestedRoles: 'Vorgeschlagene Rollen',
        roleHelpText: 'Es wurden einige Rollen aus deinem Profiltext extrahiert. Klicke auf das "+"-Symbol, um sie deinem Profil hinzuzufügen.',
        profileDescription: 'Profilbeschreibung',
    },
}