
import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed";

export interface IEmployeeDevelopmentTexts extends ITextBase
{
    newGoalDialogCertificateSelect: string;
    goal: string;
    competenceGoal: string;
    editGoalTitle: string;
    goalName: string;
    goalsHint: string;
    goalsTitle: string;
    goalDate: string;
    newGoalDialogSkillPleaceCheck: string;
    newGoalDialogSkillHint: string;
    newGoalDialogSkillSelect: string;
    newGoalDialogTitle: string;
    skillFastSelect: string;
    saving: string;
    errorMapSkill: string;
    wantedCompetencesHint: string;
    wantedSkillsHint: string;
    wantedSkillsTitle: string;
    hideSkillSuggestion: string;
    suggestedSkills: string;
    confirmHideGoal: string;
    planningCardDescription: string;
    untilDate: string;
    learnProgress: string;
    wantedCompetencesTitle: string;
    planningCardTitle: string;

}
type ILangData = {
    [lang in ELanguage]: IEmployeeDevelopmentTexts
}
export const employeedevelopmentTexts: ILangData = {
            'th': {
                newGoalDialogCertificateSelect: 'เลือกใบรับรองที่คุณต้องการที่จะได้รับ',
        goal: 'เป้าหมาย',
                competenceGoal: 'เป้าหมายความสามารถ',
                editGoalTitle: 'แก้ไขเป้าหมาย',
                goalName: 'ชื่อเป้าหมาย',
                goalsTitle: 'เป้าหมายการเรียนรู้ของคุณ',
                goalsHint: 'ด้านล่างนี้คือเป้าหมายที่คุณตั้งไว้ คุณสามารถติดตามความคืบหน้าของแต่ละเป้าหมาย และสามารถเสร็จสิ้นหรือลบได้ตามความจำเป็น',
                goalDate: 'วันที่เป้าหมาย',
                newGoalDialogSkillPleaceCheck: 'โปรดตรวจสอบทักษะและระดับของคุณก่อนที่คุณจะดำเนินการต่อ',
                newGoalDialogSkillHint: 'เมื่อคุณได้เพิ่มทักษะที่ต้องการทั้งหมดแล้ว คลิกที่ "ดำเนินการต่อ" เพื่อตั้งวันที่เป้าหมาย',
                newGoalDialogSkillSelect: 'เลือกทักษะจากรายการ และกำหนดระดับที่คุณต้องการจะถึง จากนั้นคลิกที่สัญลักษณ์ "+" เพื่อเพิ่มการเลือกลงในเป้าหมายของคุณ คุณสามารถเพิ่มทักษะได้ตามที่คุณต้องการ',
                newGoalDialogTitle: 'ตั้งเป้าหมายใหม่',
                skillFastSelect: 'การเลือกอย่างรวดเร็ว',
                errorMapSkill: 'ไม่สามารถสร้างการเชื่อมโยงทักษะ:',
                saving: 'กำลังบันทึก...',
                wantedSkillsHint: 'ความสามารถที่ต้องการเป็นความสามารถเฉพาะที่คุณตั้งใจจะเรียนรู้หรือปรับปรุง ที่นี่คุณจะเห็นการผสมผสานระหว่างเป้าหมายทักษะที่ตั้งไว้โดยตรงและทักษะที่มาจากเป้าหมายความสามารถของคุณ มันช่วยให้คุณรักษาภาพรวมของการเดินทางการเรียนรู้ของคุณ เน้นที่ทักษะที่คุณเรียนรู้เสร็จสิ้นแล้วและที่ยังคงอยู่ในรายการของคุณ',
                wantedCompetencesHint: "ส่วน 'ความสามารถที่ต้องการ' แสดงถึงบริเวณหลักที่คุณต้องการพัฒนาต่อ แต่ละความสามารถสามารถรวมถึงทักษะหลายๆ ทักษะ ที่นี่คุณสามารถดูความสามารถที่คุณได้เลือก ติดตามความก้าวหน้าในการเรียนรู้ของคุณ และระบุทักษะเฉพาะที่คุณยังต้องเรียนรู้เพื่อที่จะเชี่ยวชาญในความสามารถนั้นๆ อย่างสมบูรณ์",
                wantedSkillsTitle: 'ทักษะที่ต้องการ',
                hideSkillSuggestion: 'ซ่อนคำแนะนำทักษะ',
                suggestedSkills: 'ทักษะที่แนะนำ',
                confirmHideGoal: 'ยืนยันเป้าหมายและซ่อน',
                planningCardDescription: 'ตั้งเป้าหมายเพื่อขยายความสามารถและความสามารถของคุณ ติดตามความก้าวหน้าของคุณและสามารถบรรลุแสงแถบการทำงานของคุณ',
                untilDate: 'วันที่เป้าหมาย',
                learnProgress: 'ความคืบหน้าในการเรียนรู้',
                wantedCompetencesTitle: 'ความสามารถที่ต้องการ',
                planningCardTitle: 'เป้าหมายในการศึกษาต่อ',
                oftenUsed: oftenUsedTranslations['th'],

            },
            'en': {
                newGoalDialogCertificateSelect: 'Choose certifications you want to achieve.',
        goal: 'Goal',
                competenceGoal: 'Competence goal',
                editGoalTitle: 'Edit goal',
                goalName: 'Goal Name',
                goalsTitle: 'Your learning goals',
                goalsHint: 'Below are the goals that you have set. You can track the progress of each goal and complete or delete as necessary.',
                goalDate: 'Goal Date',
                newGoalDialogSkillPleaceCheck: 'Please check your skills and levels before you proceed.',
                newGoalDialogSkillHint: 'Once you have added all desired skills, click on "Next" to set the target date.',
                newGoalDialogSkillSelect: 'Select a skill from the list and determine the level you want to reach. Then click on the "+" symbol to add the selection to your goal. You can add as many skills as you want.',
                newGoalDialogTitle: 'Set new goal',
                skillFastSelect: 'Fast select',
                errorMapSkill: 'Skill link could not be created:',
                saving: 'Saving...',
                wantedSkillsHint: "Wanted skills refer to those specific abilities you've set out to learn or enhance. Here, you'll see a blend of directly set skill goals and the skills that stem from your competency objectives. It helps you maintain an overview of your learning journey, highlighting which skills you've already mastered and which remain on your list.",
                wantedCompetencesHint: "The 'Wanted Competencies' section displays the overarching areas you wish to develop further. Each of these competencies can encompass multiple skills. Here, you can view your selected competencies, track your learning progress, and identify the specific skills you still need to acquire to fully master a given competency.",
                wantedSkillsTitle: 'Desired Skills',
                hideSkillSuggestion: 'Hide skill suggestion',
                suggestedSkills: 'Suggested Skills',
                confirmHideGoal: 'Confirm goal & hide',
                planningCardDescription: 'Set goals to extend your skills and competencies. Track your progress and achieve your professional milestones.',
                untilDate: 'Target date',
                learnProgress: 'Learning progress',
                wantedCompetencesTitle: 'Desired competences',
                planningCardTitle: 'Further Education Goals',
                oftenUsed: oftenUsedTranslations['en'],
            },
            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                planningCardTitle: 'Weiterbildungsziele',
                wantedCompetencesTitle: 'Wunschkompetenzen',
                learnProgress: 'Lernfortschritt',
                untilDate: 'Ziel-Datum',
                planningCardDescription: 'Setz dir Ziele, um deine Fähigkeiten und Kompetenzen zu erweitern. Verfolge deinen Fortschritt und erreiche deine beruflichen Meilensteine.',
                confirmHideGoal: 'Ziel bestätigen & ausblenden',
                suggestedSkills: 'Vorgeschlagene Fähigkeiten',
                hideSkillSuggestion: 'Fähigkeitsvorschlag ausblenden',
                wantedSkillsTitle: 'Wunschfähigkeiten',
                wantedSkillsHint: 'Wunschfähigkeiten sind jene speziellen Skills, die du dir vorgenommen hast zu erlernen oder zu verbessern. Hier siehst du eine Kombination aus direkt gesetzten Fähigkeitszielen und den Fähigkeiten, die sich aus deinen Kompetenzzielen ergeben. Es unterstützt dich dabei, den Überblick über deinen Lernprozess zu behalten und zeigt auf, welche Fähigkeiten du bereits gemeistert hast und welche noch auf deiner Liste stehen.',
                wantedCompetencesHint: "Der Bereich 'Wunschkompetenzen' zeigt die übergeordneten Bereiche an, in denen du dich weiterentwickeln möchtest. Jede dieser Kompetenzen kann mehrere Fähigkeiten beinhalten. Du kannst hier deine ausgewählten Kompetenzen einsehen, deinen Lernfortschritt nachvollziehen und erkennen, welche spezifischen Fähigkeiten du noch erwerben musst, um die jeweilige Kompetenz vollständig zu meistern.",
                saving: 'Wird gespeichert...',
                errorMapSkill: 'Fähigkeitsverknüpfung konnte nicht erstellt werden:',
                skillFastSelect: 'Schnellauswahl',
                newGoalDialogTitle: 'Neues Ziel setzen',
                newGoalDialogSkillSelect: 'Wähle eine Fähigkeit aus der Liste aus und bestimme die Stufe, die du erreichen möchtest. Klicke anschließend auf das "+"-Symbol, um die Auswahl zu deinem Ziel hinzuzufügen. Du kannst beliebig viele Fähigkeiten hinzufügen.',
                newGoalDialogSkillHint: 'Wenn du alle gewünschten Fähigkeiten hinzugefügt hast, klicke auf "Weiter", um das Zieldatum festzulegen.',
                newGoalDialogSkillPleaceCheck: 'Bitte überprüfe deine Fähigkeiten und Stufen, bevor du fortfährst.',
                goalDate: 'Ziel-Datum',
                goalsHint: "Unten siehst du die Ziele, die du dir gesetzt hast. Du kannst den Fortschritt jedes Ziels verfolgen und es nach Bedarf abschließen oder löschen.",
                goalsTitle: "Deine Lernziele",
                goalName: 'Ziel-Name',
                editGoalTitle: 'Ziel bearbeiten',
                competenceGoal: 'Kompetenz Ziel',
                goal: 'Ziel',
                newGoalDialogCertificateSelect: 'Wähle Zertifizierungen aus die du erlangen möchtest.',
            },
        }