import { gql } from "@apollo/client";
import ICompanyAdmin from "@sharedInterfaces/admin/ICompanyAdmin";

import GraphQL from "../graphQL";


const q = gql`
query getCompanies($stage: String!) {
    getCompanies(stage: $stage) {
        companyId
        title
        employees
    },
}
`;


export default async function getCompanies(stage: string)
{
    const variables = {
        stage
    };
    const obj = await GraphQL.query<{ getCompanies: ICompanyAdmin[] }>(q, variables);
    if (obj && obj.getCompanies)
    {
        return obj.getCompanies
    }
    return [];

}