
import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed";

export interface IEntityPageTexts
{
    notFound: string;
    search: string;
    offline: string;
    notAllowed: string;
    edit: string;
    delete: string;
}
type ILangData = {
    [lang in ELanguage]: IEntityPageTexts
}
export const entityPageTexts: ILangData = {
    'th': {
        edit: oftenUsedTranslations['th'].edit,
        delete: oftenUsedTranslations['th'].delete,
        notAllowed: oftenUsedTranslations['th'].notAllowed,
        offline: oftenUsedTranslations['th'].offline,
        search: oftenUsedTranslations['th'].search,
        notFound: "ไม่พบวัตถุที่ร้องขอ",
    },
    'en': {
        edit: oftenUsedTranslations['en'].edit,
        delete: oftenUsedTranslations['en'].delete,
        notAllowed: oftenUsedTranslations['en'].notAllowed,
        offline: oftenUsedTranslations['en'].offline,
        search: oftenUsedTranslations['en'].search,
        notFound: "The requested object could not be found.",
    },
    'de': {
        edit: oftenUsedTranslations['de'].edit,
        delete: oftenUsedTranslations['de'].delete,
        notAllowed: oftenUsedTranslations['de'].notAllowed,
        offline: oftenUsedTranslations['de'].offline,
        search: oftenUsedTranslations['de'].search,
        notFound: 'Das Angeforderte Objekt konnte nicht gefunden werden.',
    },
}