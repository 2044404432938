import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IRolesTexts extends ITextBase
{
    pleaseEnterTitle: string;
    generateRoleData: string;
    suggestedRoleName: string;
    editButtonTitle: string;
    editRoleTitle: string;
    addRoleTitle: string;
    addCertDialogHint: string;
    openInNewTab: string;
    errorDuplicateOtherName: string;
    errorDuplicateTitle: string;
    otherNames: string;
    notAllowed: string;
    products: string;
    notFound: string;
    delete: string;
    edit: string;
    roleName: string;
    save: string;
    create: string;
    newRoleError: string;
    empty: string
    newRole: string;
    employees: string;
    employeesShort: string;
    role: string;
    employeesTitle: string;
    description: string;
    search: string;
    roles: string;
    employeesWithRole: string;
    removeButtonTitle: string;
    addButtonTitle: string;
    errorAdd: string,
    errorRemove: string
    rolesSelectedForMerge: string
    effectEmployeesWarning: string
    error: string
    pleaseEnterAName: string
}
type ILangData = {
    [lang in ELanguage]: IRolesTexts
}
export const rolesTexts: ILangData = {
    'th': {
        removeButtonTitle: 'ลบบทบาทนี้ออกจากโปรไฟล์ของคุณ',
        addButtonTitle: 'คุณมีบทบาทนี้ด้วยหรือไม่? เพิ่มลงในโปรไฟล์ของคุณ',
        errorAdd: 'เกิดข้อผิดพลาดในการเพิ่ม!',
        errorRemove: 'เกิดข้อผิดพลาดในการลบ!',
        employeesWithRole: 'พนักงานที่มีบทบาทนี้',
        role: 'บทบาท',
        roles: 'บทบาทต่างๆ',
        employeesTitle: 'จำนวนพนักงาน',
        employeesShort: 'HC',
        rolesSelectedForMerge: 'บทบาทต่อไปนี้ถูกเลือกสำหรับการรวม',
        newRole: 'บทบาทใหม่',
        pleaseEnterAName: 'กรุณาใส่ชื่อ',
        roleName: 'ชื่อบทบาท',
        newRoleError: 'เกิดข้อผิดพลาดในการบันทึก',
        errorDuplicateTitle: 'ชื่อ "[TITLE]" ไม่ถูกต้อง เนื่องจากมีบทบาทที่มีชื่อ "[TITLE]" อยู่แล้ว',
        errorDuplicateOtherName: 'ชื่อ "[VALUE]" ไม่ถูกต้อง เนื่องจากชื่อนี้ได้ถูกลงทะเบียนเป็นชื่อเรียกอื่นของบทบาท "[TITLE]" แล้ว',
        openInNewTab: 'เปิดในแท็บใหม่',
        addCertDialogHint: 'กรุณาใส่รายละเอียดของบทบาทของคุณ วันที่ออกเป็นฟิลด์ที่จำเป็นและต้องกรอก',
        addRoleTitle: 'เพิ่มบทบาทในโปรไฟล์ของฉัน',
        editRoleTitle: 'ปรับแต่งบทบาทในโปรไฟล์ของฉัน',
        editButtonTitle: 'คุณต้องการปรับแต่งรายละเอียดของบทบาทนี้ในโปรไฟล์ของคุณหรือไม่?',
        suggestedRoleName: 'ชื่อบทบาทที่แนะนำ',
        generateRoleData: 'สร้างข้อมูลบทบาทผ่าน AI',
        pleaseEnterTitle: 'กรุณาใส่ชื่อ',
        effectEmployeesWarning: "การผสานจะมีผลกับ",
        oftenUsed: oftenUsedTranslations['th'],
        error: oftenUsedTranslations['th'].error,
        notFound: oftenUsedTranslations['th'].notFound,
        edit: oftenUsedTranslations['th'].edit,
        delete: oftenUsedTranslations['th'].delete,
        description: oftenUsedTranslations['th'].description,
        employees: oftenUsedTranslations['th'].employees,
        search: oftenUsedTranslations['th'].search,
        empty: oftenUsedTranslations['th'].empty,
        create: oftenUsedTranslations['th'].create,
        save: oftenUsedTranslations['th'].save,
        products: oftenUsedTranslations['th'].products,
        notAllowed: oftenUsedTranslations['th'].notAllowed,
        otherNames: oftenUsedTranslations['th'].otherNames,

    },
    'en': {
        removeButtonTitle: 'Remove this role from your profile.',
        addButtonTitle: 'Do you also have this role? Add it to your profile.',
        errorAdd: 'Error adding!',
        errorRemove: 'Error removing!',
        employeesWithRole: 'Employees with this role',
        role: 'Role',
        roles: 'Roles',
        employeesTitle: 'Number of Employees',
        employeesShort: 'HC',
        rolesSelectedForMerge: 'The following roles have been selected for merging',
        effectEmployeesWarning: 'Merging will affect ',
        newRole: 'New Role',
        pleaseEnterAName: 'Please enter a name.',
        roleName: 'Role Name',
        newRoleError: 'Error saving',
        errorDuplicateTitle: 'The name "[TITLE]" is invalid, a role with the name "[TITLE]" already exists.',
        errorDuplicateOtherName: 'The name "[VALUE]" is invalid as it has already been registered as a synonym for the role "[TITLE]".',
        openInNewTab: 'Open in new tab',
        addCertDialogHint: 'Please enter the details of your role. The issuance date is a mandatory field and must be filled.',
        addRoleTitle: 'Add role to my profile',
        editRoleTitle: 'Adjust role in my profile',
        editButtonTitle: 'Would you like to adjust the details of this role in your profile?',
        suggestedRoleName: 'Suggested Role Name',
        generateRoleData: 'Generate role information via AI',
        pleaseEnterTitle: 'Please enter a name.',
        oftenUsed: oftenUsedTranslations['en'],
        error: oftenUsedTranslations['en'].error,
        notFound: oftenUsedTranslations['en'].notFound,
        edit: oftenUsedTranslations['en'].edit,
        delete: oftenUsedTranslations['en'].delete,
        description: oftenUsedTranslations['en'].description,
        employees: oftenUsedTranslations['en'].employees,
        search: oftenUsedTranslations['en'].search,
        empty: oftenUsedTranslations['en'].empty,
        create: oftenUsedTranslations['en'].create,
        save: oftenUsedTranslations['en'].save,
        products: oftenUsedTranslations['en'].products,
        notAllowed: oftenUsedTranslations['en'].notAllowed,
        otherNames: oftenUsedTranslations['en'].otherNames,

    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        notFound: oftenUsedTranslations['de'].notFound,
        edit: oftenUsedTranslations['de'].edit,
        delete: oftenUsedTranslations['de'].delete,
        description: oftenUsedTranslations['de'].description,
        employees: oftenUsedTranslations['de'].employees,
        search: oftenUsedTranslations['de'].search,
        error: oftenUsedTranslations['de'].error,
        empty: oftenUsedTranslations['de'].empty,
        create: oftenUsedTranslations['de'].create,
        save: oftenUsedTranslations['de'].save,
        products: oftenUsedTranslations['de'].products,
        notAllowed: oftenUsedTranslations['de'].notAllowed,
        otherNames: oftenUsedTranslations['de'].otherNames,

        removeButtonTitle: 'Entferne diese Rolle aus deinem Profil.',
        addButtonTitle: 'Besitzt du diese Rolle auch? Füge es zu deinem Profil hinzu.',
        errorAdd: 'Fehler beim Hinzufügen!',
        errorRemove: 'Fehler beim Entfernen!',
        employeesWithRole: 'Mitarbeiter mit diesem Rolle',
        role: "Rolle",
        roles: "Rollen",
        employeesTitle: 'Mitarbeiter-Anzahl',
        employeesShort: 'HC',
        rolesSelectedForMerge: 'Folgende Rollen wurden zum Zusammenfügen ausgewählt',
        effectEmployeesWarning: 'Das Zusammenfügen beeinflusst ',
        newRole: 'Neues Rolle',
        pleaseEnterAName: 'Bitte gebe einen Namen ein.',
        roleName: 'Rollesname',
        newRoleError: 'Fehler beim Speichern',
        errorDuplicateTitle: `Der Name "[TITLE]" ist ungültig, ein Rolle mit dem Namen "[TITLE]" existiert bereits.`,
        errorDuplicateOtherName: `Der Name "[VALUE]" ist ungültig da dieser bereits als Synonym für die rolle "[TITLE]" hinterlegt wurde.`,
        openInNewTab: 'In neuer Registerkarte öffnen',
        addCertDialogHint: 'Bitte gib die Details deines Rolles ein. Das Ausstellungsdatum ist ein Pflichtfeld und muss ausgefüllt werden.',
        addRoleTitle: 'Rolle meinem Profil hinzufügen',
        editRoleTitle: 'Rolle in meinem Profil anpassen',
        editButtonTitle: 'Möchtest du die Details dieser Rolles in deinem Profil anpassen?',
        suggestedRoleName: 'Vorgeschlagener Rollesname',
        generateRoleData: 'Rollesinformationen per AI generieren',
        pleaseEnterTitle: 'Bitte gib einen Namen ein.',
    },
}