import { businessesTexts, businesssTexts } from "./languageTexts/business";
import { IAnalyseDialogTexts, analysedialogTexts } from "./languageTexts/analyseDialog";
import { IAvailabilitiesTexts, availabilitiesTexts } from "./languageTexts/availability";
import { ICategorizeDialogTexts, categorizedialogTexts } from "./languageTexts/categorizeDialog";
import { ICertificatesTexts, certificatesTexts } from "./languageTexts/certificates";
import { ICompetenceTexts, competenceTexts } from "./languageTexts/competence";
import { IEmployeesTexts, employeesTexts } from "./languageTexts/employees";
import { IEntityPageTexts, entityPageTexts } from "./languageTexts/entityPage";
import { IEntityTypesTexts, entityTypesTexts } from "./languageTexts/IEntityTypesTexts";
import { IFirstExpirienceDialogTexts, firstExpirienceDialog } from "./languageTexts/firstExpirienceDialogTexts";
import { IHelpDialogTexts, helpdialogTexts } from "./languageTexts/helpDialog";
import { ILayoutTexts, layoutTexts } from "./languageTexts/layout";
import { ILoggedinTexts, loggedinTexts } from "./languageTexts/loggedin";
import { INavigationTexts, navigationTexts } from "./languageTexts/navigation";
import { INotloggedinTexts, notloggedinTexts } from "./languageTexts/notloggedin";
import { IOppoTexts, oppoTexts } from "./languageTexts/opportunities";
import { IOrgUnitTexts, orgunitTexts } from "./languageTexts/orgUnit";
import { IProductsTexts, productsTexts } from "./languageTexts/products";
import { IProfileSettingsTexts, profilesettingsTexts } from "./languageTexts/profileSettings";
import { IProfileTexts, profileTexts } from "./languageTexts/profile";
import { IProjectsTexts, projectsTexts } from "./languageTexts/projects";
import { IRegistrationConfig, registrationConfigTexts } from "./languageTexts/registrationConfig";
import { ISalesTexts, salesTexts } from "./languageTexts/sales";
import { ISettingsTexts, settingsTexts } from "./languageTexts/settings";
import { ISkillCategoriesTexts, skillcategoriesTexts } from "./languageTexts/skillCategories";
import { ISkillTexts, skillTexts } from "./languageTexts/skills"
import { IUserDialogTexts, userdialogTexts } from "./languageTexts/userDialog";
import { oftenUsedTranslations } from "./languageTexts/oftenUsed";
import { ICountriesTexts, countriesTexts } from "./languageTexts/countries";
import { ILanugageNamesTexts, lanugagenamesTexts } from "./languageTexts/lanugageNames";
import { IEmployeeDevelopmentTexts, employeedevelopmentTexts } from "./languageTexts/employeedevelopmentTexts";
import { IStartPagesTexts, startPagesTexts } from "./languageTexts/startPages";
import { IErrorTexts, errorTexts } from "./languageTexts/errorTexts";
import { IRolesTexts, rolesTexts } from "./languageTexts/roles";
import { IEmailTexts, emailTexts } from "./languageTexts/emailTexts";


export enum ELanguage
{
    de = 'de',
    en = 'en',
    th = 'th'
}

export interface ILanguage
{
    error: IErrorTexts;
    analyseDialog: IAnalyseDialogTexts,
    availability: IAvailabilitiesTexts,
    businesses: businessesTexts
    categorizeDialog: ICategorizeDialogTexts;
    certificates: ICertificatesTexts,
    competence: ICompetenceTexts;
    countries: ICountriesTexts,
    employeeDevelopment: IEmployeeDevelopmentTexts,
    employees: IEmployeesTexts,
    entityPage: IEntityPageTexts
    entityTypes: IEntityTypesTexts;
    firstExpirienceDialog: IFirstExpirienceDialogTexts;
    helpDialog: IHelpDialogTexts;
    languageNames: ILanugageNamesTexts;
    layout: ILayoutTexts,
    loading: string
    loggedin: ILoggedinTexts,
    navigation: INavigationTexts,
    notloggedin: INotloggedinTexts,
    opportunities: IOppoTexts;
    orgUnit: IOrgUnitTexts,
    products: IProductsTexts,
    profile: IProfileTexts,
    profileSettings: IProfileSettingsTexts,
    projects: IProjectsTexts,
    registrationConfig: IRegistrationConfig;
    sales: ISalesTexts,
    settings: ISettingsTexts,
    skillCategories: ISkillCategoriesTexts,
    skills: ISkillTexts,
    startPages: IStartPagesTexts;
    userDialog: IUserDialogTexts,
    roles: IRolesTexts,
    emailTexts: IEmailTexts
}

/**
 * Retrieves the language data.
 * 
 * @returns {ILangData} The language data.
 */
function getLangData(): ILangData
{
    const data: ILangData = {} as ILangData;
    Object.values(ELanguage).forEach(lang =>
    {
        data[lang] = {
            analyseDialog: (analysedialogTexts[lang] ? analysedialogTexts[lang] : analysedialogTexts['en']),
            availability: (availabilitiesTexts[lang] ? availabilitiesTexts[lang] : availabilitiesTexts['en']),
            businesses: (businesssTexts[lang] ? businesssTexts[lang] : businesssTexts['en']),
            categorizeDialog: (categorizedialogTexts[lang] ? categorizedialogTexts[lang] : categorizedialogTexts['en']),
            certificates: (certificatesTexts[lang] ? certificatesTexts[lang] : certificatesTexts['en']),
            competence: (competenceTexts[lang] ? competenceTexts[lang] : competenceTexts['en']),
            employees: (employeesTexts[lang] ? employeesTexts[lang] : employeesTexts['en']),
            entityPage: (entityPageTexts[lang] ? entityPageTexts[lang] : entityPageTexts['en']),
            entityTypes: (entityTypesTexts[lang] ? entityTypesTexts[lang] : entityTypesTexts['en']),
            firstExpirienceDialog: (firstExpirienceDialog[lang] ? firstExpirienceDialog[lang] : firstExpirienceDialog['en']),
            helpDialog: (helpdialogTexts[lang] ? helpdialogTexts[lang] : helpdialogTexts['en']),
            layout: (layoutTexts[lang] ? layoutTexts[lang] : layoutTexts['en']),
            loading: (oftenUsedTranslations[lang] ? oftenUsedTranslations[lang] : oftenUsedTranslations['en']).loading,
            loggedin: (loggedinTexts[lang] ? loggedinTexts[lang] : loggedinTexts['en']),
            navigation: (navigationTexts[lang] ? navigationTexts[lang] : navigationTexts['en']),
            notloggedin: (notloggedinTexts[lang] ? notloggedinTexts[lang] : notloggedinTexts['en']),
            opportunities: (oppoTexts[lang] ? oppoTexts[lang] : oppoTexts['en']),
            orgUnit: (orgunitTexts[lang] ? orgunitTexts[lang] : orgunitTexts['en']),
            products: (productsTexts[lang] ? productsTexts[lang] : productsTexts['en']),
            profile: (profileTexts[lang] ? profileTexts[lang] : profileTexts['en']),
            profileSettings: (profilesettingsTexts[lang] ? profilesettingsTexts[lang] : profilesettingsTexts['en']),
            projects: (projectsTexts[lang] ? projectsTexts[lang] : projectsTexts['en']),
            registrationConfig: (registrationConfigTexts[lang] ? registrationConfigTexts[lang] : registrationConfigTexts['en']),
            sales: (salesTexts[lang] ? salesTexts[lang] : salesTexts['en']),
            settings: (settingsTexts[lang] ? settingsTexts[lang] : settingsTexts['en']),
            skillCategories: (skillcategoriesTexts[lang] ? skillcategoriesTexts[lang] : skillcategoriesTexts['en']),
            skills: (skillTexts[lang] ? skillTexts[lang] : skillTexts['en']),
            userDialog: (userdialogTexts[lang] ? userdialogTexts[lang] : userdialogTexts['en']),
            countries: (countriesTexts[lang] ? countriesTexts[lang] : countriesTexts['en']),
            languageNames: (lanugagenamesTexts[lang] ? lanugagenamesTexts[lang] : lanugagenamesTexts['en']),
            employeeDevelopment: (employeedevelopmentTexts[lang] ? employeedevelopmentTexts[lang] : employeedevelopmentTexts['en']),
            startPages: (startPagesTexts[lang] ? startPagesTexts[lang] : startPagesTexts['en']),
            error: (errorTexts[lang] ? errorTexts[lang] : errorTexts['en']),
            roles: (rolesTexts[lang] ? rolesTexts[lang] : rolesTexts['en']),
            emailTexts: (emailTexts[lang] ? emailTexts[lang] : emailTexts['en'])
        }
    });

    return data;
}

/**
 * Retrieves the language texts based on the specified language.
 *
 * @param {string} lang - The language code.
 * 
 * @returns {ILanguage} The language texts.
 */
export function getLanguageTexts(lang: ELanguage)
{
    // return langData['th'];
    const langData = getLangData();
    if (lang in ELanguage)
    {
        return langData[lang as ELanguage];
    }
    return langData['en'];
}

/**
 * Function to find and return a matching language.
 *
 * @param {readonly string[]} langs - An array of languages to search for a match.
 * @returns {string} The matching language.
 */
export function findMatchingLanguage(langs: readonly string[]): ELanguage
{
    const shortLangs = langs.map(lang =>
    {
        const index = lang.indexOf('-');
        if (index > -1)
        {
            const shortLang = lang.substring(0, index);
            return shortLang;
        }
        return lang;
    })

    const wantedLang = shortLangs.find(lang => lang in ELanguage);

    if (!wantedLang)
    {
        return ELanguage.en;
    }
    return wantedLang as ELanguage;
}

type ILangData = {
    [lang in ELanguage]: ILanguage
}

export const languageToCountryMap: Record<ELanguage, string[]> = {
    de: ['de', 'at', 'ch', 'li', 'lu', 'be'],
    en: ['gb', 'usa', 'au', 'ca', 'ie', 'nz', 'sg', 'za'],
    th: ['th']
};

export const countryToLanguageMap: Record<string, ELanguage> = {};

// Füllen der umgekehrten Zuordnung
for (const [language, countries] of Object.entries(languageToCountryMap))
{
    for (const country of countries)
    {
        countryToLanguageMap[country] = language as ELanguage;
    }
}

// Funktion, um die Sprache eines Landes zu erhalten
/**
 * Retrieves the language of a given country.
 * @param {string} country - The country for which the language is to be retrieved.
 * @returns {ELanguage} - The language of the country.
 */
export function getLanguageOfCountry(country: keyof ICountriesTexts): ELanguage
{
    return countryToLanguageMap[country];
}