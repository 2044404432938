import React from 'react';
import { Menu } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AppState } from '@store/store';
import UserButton from '@src/Components/UserButton/UserButton';
import './Header.css';

interface HeaderProps
{
  toggleNav: () => void;
  navIsOpen: boolean;
}

/**
 * Renders the header component.
 *
 * @param {HeaderProps} toggleNav - The toggleNav function.
 * @param {HeaderProps} navIsOpen - The navIsOpen boolean value.
 *
 * @returns {JSX.Element} The rendered header component.
 */
function Header({ toggleNav, navIsOpen }: HeaderProps)
{
  const companyName = useSelector((state: AppState) => state.company.name);
  const width = useSelector((state: AppState) => state.windowSize.width);

  return (
    <header className="header">
      {
        companyName !== '' &&
        <div
          className={"navButton" + (navIsOpen ? ' navIsOpen' : '')}
          onClick={
            () =>
            {
              toggleNav();
            }
          }
        >
          <Menu />
        </div>
      }

      <div className="title">CompetenceManager - Admin Center</div>
      <div className='rightOptions'>
        <UserButton />
      </div>
    </header >
  );
}

export default Header;