export interface GeneralState
{
    selectedStage: string | null
    stages: string[]
}


export const initialGeneralState: GeneralState = {
    selectedStage: null,
    stages: []
};


export enum ESetGeneralAction
{
    SET_STAGES = 'SET_STAGES',
    SET_SELECTED_STAGE = 'SET_SELECTED_STAGE'
}

// Action interface for setting the company name
interface SetStagesAction
{
    type: ESetGeneralAction.SET_STAGES;
    payload: string[];
}
interface SetSelectedStageAction
{
    type: ESetGeneralAction.SET_SELECTED_STAGE;
    payload: string | null;
}
//
type CompanyAction =
    SetStagesAction
    | SetSelectedStageAction;

export const generalReducer = (state = initialGeneralState, action: CompanyAction): GeneralState =>
{
    switch (action.type)
    {
        case ESetGeneralAction.SET_STAGES:
            return {
                ...state,
                stages: action.payload
            }
        case ESetGeneralAction.SET_SELECTED_STAGE:
            return {
                ...state,
                selectedStage: action.payload
            }
        default:
            return state;
    }
};


export const setStages = (stages: string[]): SetStagesAction => ({
    type: ESetGeneralAction.SET_STAGES,
    payload: stages,
});

export const setSelectedStage = (stage: string | null): SetSelectedStageAction =>
{
    if (stage === '') stage = null;
    return {
        type: ESetGeneralAction.SET_SELECTED_STAGE,
        payload: stage,
    };
};