import { ELanguage } from "../languageHelper";

export interface ICountriesTexts
{
    de: string
    gb: string
    usa: string
    th: string
    au: string
    ca: string
    ie: string
    nz: string
    sg: string
    za: string
    at: string
    ch: string
    li: string
    lu: string
    be: string
}
type ILangData = {
    [lang in ELanguage]: ICountriesTexts
}
export const countriesTexts: ILangData = {
    'th': {
        be: 'เบลเยียม',
        lu: 'ลักเซมเบิร์ก',
        li: 'ลิกเตนสไตน์',
        ch: 'สวิตเซอร์แลนด์',
        at: 'ออสเตรีย',
        za: 'แอฟริกาใต้',
        sg: 'สิงคโปร์',
        nz: 'นิวซีแลนด์',
        ie: 'ไอร์แลนด์',
        ca: 'แคนาดา',
        au: 'ออสเตรเลีย',
        de: 'เยอรมัน',
        th: 'ไทย',
        usa: 'สหรัฐฯ',
        gb: 'สหราชอาณาจักร',
    },
    'en': {
        be: 'Belgium',
        lu: 'Luxembourg',
        li: 'Liechtenstein',
        ch: 'Switzerland',
        at: 'Austria',
        za: 'South Africa',
        sg: 'Singapore',
        nz: 'New Zealand',
        ie: 'Ireland',
        ca: 'Canada',
        au: 'Australia',
        de: 'Germany',
        th: 'Thailand',
        usa: 'USA',
        gb: 'United Kingdom',
    },
    'de': {
        de: 'Deutschland',
        gb: 'Großbritannien',
        usa: 'USA',
        th: 'Thailand',
        au: 'Australien',
        ca: 'Kanada',
        ie: 'Irland',
        nz: 'Neuseeland',
        sg: 'Singapur',
        za: 'Südafrika',
        at: 'Österreich',
        ch: 'Schweiz',
        li: 'Lichtenstein',
        lu: 'Luxemburg',
        be: 'Belgien',
    },
}