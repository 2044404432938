import React from 'react';
import { useSelector } from 'react-redux';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';

import { createLoginUrl } from '../Components/UserButton/UserButton';


/**
 * "NotAllowed" function.
 *
 * @returns {JSX.Element} - The JSX element.
 */
function NotAllowed()
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).notloggedin;
    return (
        <InnerLayout title={langStrings.title}>
            <span>{langStrings.text}<a style={{
                textDecoration: 'underline',
                color: 'var(--var-primary-color)',
            }} className='login' href={createLoginUrl(window.location.hostname)}>{langStrings.login}</a></span>
        </InnerLayout>
    );
}

export default NotAllowed;
