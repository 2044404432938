import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './Layout.css';
import VersionDialog from '@sharedReact/Dialog/Dialogs/VersionDialog/VersionDialog';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { ELinks } from '@sharedInterfaces/globalEnums';
import { AppState } from '@store/store';
import Header from '@src/Components/Header/Header';
import { isLoggedIn } from '@store/reducer/clientReducer';

import Navigation from '../../../Components/Navigation/Navigation';

interface LayoutProps
{
  inDialog?: boolean;

  children: JSX.Element;
}

/**
 * Renders the Layout component.
 *
 * @param {LayoutProps} props - The props object which contains the necessary data for rendering the Layout.
 * @returns {JSX.Element} - The rendered Layout component.
 */
function Layout(props: LayoutProps)
{
  const lang = useSelector((state: AppState) => state.employee.language);
  const langStrings = getLanguageTexts(lang).layout;
  const width = useSelector((state: AppState) => state.windowSize.width);

  const loggedIn = useSelector((state: AppState) => isLoggedIn(state.client.secret));

  const overflowNav = width <= 700;
  const [navIsOpen, setNavIsOpen]: [boolean, (navIsOpen: boolean) => void] = React.useState((width <= 700 ? false : true) as boolean);

  if (props.inDialog) return props.children
  return (
    <React.Fragment>
      <Header toggleNav={() =>
      {
        setNavIsOpen(!navIsOpen);
      }}
        navIsOpen={navIsOpen}
      />
      <div className='outerWrapper'>
        <div className={`wrapper`}>
          <Navigation
            fixedNav={overflowNav}
            isOpen={navIsOpen}
            loggedIn={loggedIn}
            setNavIsOpen={setNavIsOpen}
          />
          <div
            className='contentOuter'
          >
            {props.children}
          </div>
        </div>
        <div className='footer'>
          <NavLink className='imprint' to={ELinks.FOOTER_IMPRINT}>
            {langStrings.imprint}
          </NavLink>
          <NavLink className='contact' to={ELinks.FOOTER_CONTACT}>
            {langStrings.contact}
          </NavLink>
          <span className='copyRight'>{
            width < 450 ?
              langStrings.copyrightShort :
              langStrings.copyright
          }Mirco Frye</span>
          <VersionDialog />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Layout;
