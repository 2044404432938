import { combineReducers } from 'redux';
import { Middleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { ICookieSettingsState, cookieSettingsReducer, ECookieSettingsAction, initialCookieSettingsState } from './reducer/cookieReducer';
import { EClientAction, IClientState, clientReducer, initialClientState } from './reducer/clientReducer';
import { EmployeeActionTypes, EmployeeState, employeeReducer, initialEmployeeState } from './reducer/employeeReducer';
import { WindowSizeState, windowSizeReducer } from './reducer/windowSizeReducer';
import { CompanyState, ESetCompanyAction, companyReducer, initialCompanyState } from './reducer/companyReducer';
import { ESetGeneralAction, GeneralState, generalReducer, initialGeneralState } from './reducer/generalReducer';

export interface AppState
{
    general: GeneralState
    company: CompanyState
    windowSize: WindowSizeState
    employee: EmployeeState
    cookies: ICookieSettingsState
    client: IClientState
}

const rootReducer = combineReducers({
    general: generalReducer,
    company: companyReducer,
    windowSize: windowSizeReducer,
    employee: employeeReducer,
    cookies: cookieSettingsReducer,
    client: clientReducer,
});


const localStorageMiddleware: Middleware<AppState, AppState> = store => next => action =>
{
    const result = next(action);
    const state = store.getState();
    const save = async () =>
    {
        if (action.type in ESetGeneralAction)
            localStorage.setItem('general', JSON.stringify(state.general));
        else if (action.type in ESetCompanyAction)
            localStorage.setItem('company', JSON.stringify(state.company));
        else if (action.type in ECookieSettingsAction)
            localStorage.setItem('cookies', JSON.stringify(state.cookies));
        else if (action.type in EClientAction)
            localStorage.setItem('client', JSON.stringify(state.client));
        else if (action.type in EmployeeActionTypes)
            localStorage.setItem('employee', JSON.stringify(state.employee));
    }
    save();
    return result;
};

const loadState = (): Partial<AppState> =>
{
    try
    {
        return {
            general: JSON.parse(localStorage.getItem('general') || JSON.stringify(initialGeneralState)),
            employee: JSON.parse(localStorage.getItem('employee') || JSON.stringify(initialEmployeeState)),
            client: JSON.parse(localStorage.getItem('client') || JSON.stringify(initialClientState)),
            cookies: (JSON.parse(localStorage.getItem('cookies') || JSON.stringify(initialCookieSettingsState))),
            company: (JSON.parse(localStorage.getItem('company') || JSON.stringify(initialCompanyState))),
        };
    }
    catch (ex)
    {
        console.error(ex);
        const client = localStorage.getItem("client");
        const cookies = localStorage.getItem("cookies");
        localStorage.clear();
        client && localStorage.setItem("client", client);
        localStorage.setItem("cookies", cookies || JSON.stringify(initialCookieSettingsState));
        location.reload();
    }
    return {}
};

const store = configureStore({
    reducer: rootReducer,
    middleware:
        (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            })
                .concat(localStorageMiddleware),
    preloadedState: loadState(),
});
export default store;
