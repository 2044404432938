import { gql } from "@apollo/client";
import { ICompanyPageAdmin } from "@sharedInterfaces/admin/ICompanyAdmin";

import GraphQL from "../graphQL";

const permissionGql = `{
  Add
  Delete
  Edit
  Retrieve
  RetrieveOwn
  RetrieveSalesData
}`;

const permissionEntitiesGql = `entities {
    Certificates ${permissionGql}
    Companies ${permissionGql}
    Competencies ${permissionGql}
    Products ${permissionGql}
    Projects ${permissionGql}
    Skills ${permissionGql}
    SkillCategories ${permissionGql}
    Opportunities ${permissionGql}
    OrgUnits ${permissionGql}
    Roles ${permissionGql}
}`

const q = gql`
query getCompany($stage: String!, $companyId: String!) {
    getCompany(stage: $stage, companyId: $companyId) {
    versions{
        absence
        product
        smallCompetence
        smallSkill
        certificate
        business
        employee
        smallEmployee
        project
        skillcategory
        skill
        empmonthproject
        empsettings
        availmonth
        role
        smallRole
        smallCertificate
        competence
        aiRequest
        opportunity
    }
    companyObj {
        companyId
            name
            employees {
                id
                userName
                firstname
                lastname
                gender
                email
                permissions {
                    admin
                    ${permissionEntitiesGql}
                }
                permissionGroup {
                    id
                    title
                }
            }
            style {
                primary
                secondary
                onSecondary
                onPrimary
            }
            OUs {
                id
                parentOU
                title
                leader
                employees
            }
            level {
                level
                title
                description
                helperText
            }
            permissionGroups {
                id
                title
                ${permissionEntitiesGql}
            }
            allowedMailEndings
            calculations {
                forecastMonthCount
                workedMonthCount
            }
            defaultPermissionGroup {
                id
                title
            }
            clockodoSync
            localisation {
                country
                subCountry
                language
            }
            industry
            holidaySettings {
                source
                holidays {
                    year
                    holidays {
                    date
                    name
                    isFullDay
                    }
                }
            }
            version
    }
  }
}
`;


export default async function getCompany(stage: string, companyId: string)
{
    const variables = {
        stage,
        companyId
    };
    const obj = await GraphQL.query<{ getCompany: ICompanyPageAdmin }>(q, variables);
    if (obj && obj.getCompany)
    {
        return obj.getCompany
    }
    return null;
}