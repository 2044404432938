
import { ICompetenceLevelSkill } from "./ICompetence";
import { MaybeSkill, IEmployeeCoverage, ISkillLevelCompare } from "./IOpportunity";
import { IReference } from "./IReference";
import { ISkillReferenceDTO } from "./ISkill";

/**
 * Generates a full name by concatenating the first name and last name.
 * 
 * @param {string} firstName - The first name of the person.
 * @param {string} lastName - The last name of the person.
 * @returns {string} The full name generated by combining the first name and last name.
 */
export function generateFullName(firstName: string, lastName: string): string
{
    return `${firstName} ${lastName}`
}

/**
 * Converts a given date to ISO date format. (2023-01-01)
 * 
 * @param {Date} date - The date to convert.
 * @returns {string} The date in ISO format.
 */
export function toISODateFormat(date: Date): string
{
    if (typeof date === 'string')
        return (date as string).split('T')[0];
    else if (date instanceof Date)
        return date.toISOString().split('T')[0];
    else
        throw new Error('Invalid date type');
}


/**
 * Inserts a string at a specific index in another string.
 * 
 * @param {string} str - The string in which the insertion will occur.
 * @param {number} index - The index at which the string will be inserted.
 * @param {string} insertion - The string that will be inserted.
 * @return {string} The resulting string after the insertion.
 */
export function insertAt(str: string, index: number, insertion: string): string
{
    return str.slice(0, index) + insertion + str.slice(index);
}

/**
* Checks if a given date is a workday.
*
* @param {Date} date - The date to check.
* @return {boolean} - True if the date is a workday, false otherwise.
*/
export function isWorkday(date: Date)
{
    const dayOfWeek = date.getDay()
    return !((dayOfWeek === 6) || (dayOfWeek === 0));
}

/**
 * Formats a date into a localized string representation based on the given locale.
 * 
 * @param {string | Date} date - The date to be formatted.
 * @param {string} locale - The locale used for formatting the date.
 * 
 * @returns {string} The formatted date as a localized string.
 */
export function formatDateLocalized(date: Date | string | null | undefined, locale: string)
{
    if (date === null || date === undefined)
    {
        return '-';
    }

    const dateObj = typeof date === 'string' ? new Date(date) : date;

    // Überprüfen, ob das Datum gültig ist
    if (isNaN(dateObj.getTime()))
    {
        return '-';
    }

    return dateObj.toLocaleDateString(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}

export interface RelevantEmployees
{
    [key: number]: {
        id: number;
        title: string;
        skills: {
            [key: number]: number;
        };
        certificates: {
            [key: number]: number;
        };
    };
}


/**
 * Calculates suggested employees based on requested skills and relevant employees' skill coverage.
 *
 * @param {MaybeSkill[]} requestedSkills - The array of requested skills.
 * @param {MaybeSkill[]} requestedCertificates - The array of requested certificates.
 * @param {RelevantEmployees} relevantEmployees - The object representing relevant employees' skill coverage.
 * @return {IEmployeeCoverage[]} - The array of suggested employees and their skill coverage.
 */
export function calculateSuggestedEmployees(
    requestedSkills: MaybeSkill[],
    requestedCertificates: IReference[],
    relevantEmployees: RelevantEmployees
): IEmployeeCoverage[]
{
    const result: IEmployeeCoverage[] = [];

    for (const employeeId in relevantEmployees)
    {
        const employee = relevantEmployees[employeeId];
        const skillMap: { [key: number]: ISkillReferenceDTO } = {};
        for (const skillId in employee.skills)
        {
            skillMap[parseInt(skillId)] = {
                id: parseInt(skillId),
                title: "",  // Anpassen, falls der Titel benötigt wird
                level: employee.skills[skillId]
            };
        }

        const certificateMap: { [key: number]: IReference } = {};
        requestedCertificates.forEach(rc =>
        {
            if (employee.certificates[rc.id])
            {
                certificateMap[rc.id] = rc;
            }
        });

        const { skillCoverage, score, missingSkills, coveredSkillsList, missingCertificates, coveredCertificatesList } =
            calculateCoverageForOne(
                requestedSkills,
                skillMap,
                requestedCertificates,
                certificateMap
            );

        result.push({
            id: employee.id,
            title: employee.title,
            skillCoverage,
            score: Math.max(0, Math.min(1, score)),
            missingSkills,
            coveredSkills: coveredSkillsList,
            missingCertificates,
            coveredCertificates: coveredCertificatesList
        });
    }

    result.sort((a, b) => b.score - a.score);

    return result;
}


/**
 * Calculates the coverage for one opportunity.
 * 
 * @param requestedSkills - The array of requested skills for the opportunity.
 * @param skillMap - The map of skill references.
 * @returns The coverage information, including the skill coverage, score, missing skills, and covered skills list.
 */
function calculateCoverageForOne(
    requestedSkills: MaybeSkill[],
    skillMap: { [key: number]: ISkillReferenceDTO },
    requestedCertificates: IReference[],
    certificateMap: { [key: number]: IReference }
):
    {
        skillCoverage: number;
        score: number;
        missingSkills: ISkillLevelCompare[];
        coveredSkillsList: ISkillLevelCompare[]
        missingCertificates: IReference[];
        coveredCertificatesList: IReference[];
    }
{
    let totalPossibleScore = 0;
    let actualScore = 0;
    let coveredSkills = 0;
    const missingSkills: ISkillLevelCompare[] = [];
    const coveredSkillsList: ISkillLevelCompare[] = [];

    for (const { id, title, level } of requestedSkills)
    {
        totalPossibleScore += level;
        const skill = id ? skillMap[id] : undefined;

        if (!skill)
        {
            missingSkills.push({ id, title, level: 0, wantedLevel: level });
            continue;
        }

        const { level: employeeSkillLevel } = skill;
        actualScore += Math.min(level, employeeSkillLevel);

        coveredSkills++;
        coveredSkillsList.push({ id, title, level: employeeSkillLevel, wantedLevel: level });
    }

    const skillCoverage = requestedSkills.length ?
        coveredSkills / requestedSkills.length :
        0;
    const skillScore = totalPossibleScore ?
        actualScore / totalPossibleScore :
        0;


    // Calculate missing certificates
    const missingCertificates: IReference[] = [];
    const coveredCertificatesList: IReference[] = [];
    for (const { id, title } of requestedCertificates)
    {
        const certificate = id ? certificateMap[id] : undefined;
        if (!certificate)
        {
            missingCertificates.push({ id, title });
        } else
        {
            coveredCertificatesList.push({ id, title });
        }
    }


    return { skillCoverage, score: skillScore, missingSkills, coveredSkillsList, missingCertificates, coveredCertificatesList };
}

/**
 * Calculate the employee skill coverage for the given requested skills and skills.
 *
 * @param {MaybeSkill[]} requestedSkills - The array of requested skills.
 * @param {ISkillReferenceDTO[]} skills - The array of employee skills.
 * @returns {IEmployeeCoverage} - The employee skill coverage.
 */
export function calculateEmployeeCoverage(
    requestedSkills: MaybeSkill[],
    skills: ISkillReferenceDTO[],
    requestedCertificates: IReference[],
    certificates: IReference[]

): IEmployeeCoverage
{
    const skillMap = Object.fromEntries(skills.map((skill) => [skill.id, skill]));
    const certificateMap = Object.fromEntries(certificates.map((certificate) => [certificate.id, certificate]));
    const {
        skillCoverage,
        score, missingSkills,
        coveredSkillsList,
        missingCertificates,
        coveredCertificatesList
    } = calculateCoverageForOne(requestedSkills, skillMap, requestedCertificates, certificateMap);
    return {
        id: 0,
        title: "",
        skillCoverage,
        score: Math.max(0, Math.min(1, score)),
        missingSkills,
        coveredSkills: coveredSkillsList,
        missingCertificates,
        coveredCertificates: coveredCertificatesList
    };
}

/**
 * Calculate the learning progress based on the current skills and previous coverage.
 * 
 * @param {ISkillReferenceDTO[]} currentSkills - The array of current skills.
 * @param {IEmployeeCoverage} previousCoverage - The previous coverage of employee skills.
 * 
 * @returns {number} The calculated learning progress.
 */
export function calculateLearningProgress(
    currentSkills: ISkillReferenceDTO[],
    previousCoverage: IEmployeeCoverage,
    actualSkills: ICompetenceLevelSkill[]
): number
{
    let actualProgress = 0;
    let totalExpectedProgress = 0;

    const currentSkillMap = Object.fromEntries(
        currentSkills.map((skill) => [skill.id, skill])
    );

    const actualSkillMap = Object.fromEntries(
        actualSkills.map((skill) => [skill.id, skill])
    );

    const combinedSkills = [...previousCoverage.missingSkills, ...previousCoverage.coveredSkills]
    const allSkills = actualSkills.map(actualSkill =>
    {
        const currentSkill = currentSkillMap[actualSkill.id];
        const existingSkill = combinedSkills.find(skill => skill.id === actualSkill.id);
        const level = existingSkill ? existingSkill.level : 0;
        return {
            id: actualSkill.id,
            title: actualSkill.title,
            level: Math.min(level, currentSkill ? currentSkill.level : 0),
            wantedLevel: actualSkill.level
        };
    });


    for (const skill of allSkills)
    {
        const initialLevel = skill.level || 0;
        const wantedLevel = skill.id ? actualSkillMap[skill.id]?.level ?? 0 : 0;

        if (wantedLevel > initialLevel)
        {
            totalExpectedProgress += wantedLevel - initialLevel;
        }

        if (skill.id !== undefined)
        {
            const currentSkill = currentSkillMap[skill.id];
            if (currentSkill)
            {
                const levelGained = Math.min(currentSkill.level, wantedLevel) - initialLevel;
                if (levelGained > 0)
                {
                    actualProgress += levelGained;
                }
            }
        }
    }

    if (totalExpectedProgress === 0)
    {
        return 1;
    }

    return Math.min(1, actualProgress / totalExpectedProgress);
}

