import React, { useEffect } from "react";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import InnerLayout from "@sharedReact/Layouts/InnerLayout/InnerLayout";
import { AppState } from "@store/store";
import { useSelector } from "react-redux";
import { ICompanyPageAdmin } from "@sharedInterfaces/admin/ICompanyAdmin";
import { useParams } from "react-router-dom";
import LoadingBox from "@sharedReact/General/LoadingBox/LoadingBox";
import JsonViewEditor from '@uiw/react-json-view/editor';
import getCompany from "@src/APIs/graphQl/Company/getCompany";
import { showErrorDialog } from "@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog";
import RowElement from "@sharedReact/General/Forms/RowElement/RowElement";

export default function CompanyPage()
{
    const { idString } = useParams();
    const id = idString ? idString : null;
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang);

    const selectedStage = useSelector((state: AppState) => state.general.selectedStage);

    const [pageData, setPageData] = React.useState<ICompanyPageAdmin | null>(null);

    useEffect(
        () =>
        {
            if (!selectedStage || !id) return;
            getCompany(selectedStage, id)
                .then((company) =>
                {
                    if (!company)
                    {
                        return showErrorDialog(new Error("Firma nicht gefunden?!"));
                    }
                    setPageData(company);
                })
                .catch(showErrorDialog);
        },
        [selectedStage, id]
    )

    return (
        <InnerLayout title={pageData ?
            `${langStrings.layout.oftenUsed.company} - ${pageData.companyObj.name}` :
            langStrings.layout.oftenUsed.company}>
            {!pageData && <LoadingBox />}
            {pageData && <>
                <RowElement title={'Versions'}>
                    <JsonViewEditor
                        displayDataTypes={false}
                        value={pageData.versions} />
                </RowElement>
                <RowElement title={langStrings.layout.oftenUsed.company}>
                    <JsonViewEditor
                        displayDataTypes={false}
                        value={pageData.companyObj} />
                </RowElement>
            </>}
        </InnerLayout>
    );
}