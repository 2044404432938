import React, { useState } from 'react';
import { Fab } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';

/**
 * Represents a HoverFab component.
 *
 * @returns {JSX.Element}
 */
export function HoverFab(): JSX.Element
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang);
    const offline = useSelector((state: AppState) => state.client.offline);

    const [isHover, setHover] = useState(false);
    let timer: NodeJS.Timeout;

    const handleMouseEnter = () =>
    {
        if (offline) return;
        clearTimeout(timer);
        setHover(true);
    };

    const handleMouseLeave = () =>
    {
        timer = setTimeout(() =>
        {
            setHover(false);
        }, 750);
    };
    return (
        <div
            style={{
                position: 'sticky',
                float: 'right',
                bottom: 10,
                right: 10,
                marginTop: -56,
                width: 56,
                alignSelf: 'flex-end',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Fab //TODO: Check Speeddial mui
                title={langStrings.layout.createSkill}
                disabled={offline}
                color="primary"
                style={{
                    position: 'sticky',
                    float: 'right',
                    bottom: 15,
                    right: 15,
                    transition: 'background-color 0.5s',
                }}
                onClick={() =>
                {
                    if (!isHover) return;
                    console.log("Hover Click");
                }}
            >
                {<Add />}
            </Fab>
        </div>
    );
}
