import { IStyleDTO } from "@sharedInterfaces/ICompanySettings";

export interface CompanyState
{
    name: string
    style: IStyleDTO
}


export const initialCompanyState: CompanyState = {
    name: '',
    style: {
        primary: '#0190ce',
        secondary: '#004766',
        onPrimary: '#ffffff',
        onSecondary: '#ffffff',
    },
};


export enum ESetCompanyAction
{
    SET_COMPANY_NAME = 'SET_COMPANY_NAME',
    SET_COMPANY_STYLE = "SET_COMPANY_STYLE",
}

// Action interface for setting the company name
interface SetCompanyNameAction
{
    type: ESetCompanyAction.SET_COMPANY_NAME;
    payload: string;
}

// Action interface for setting style
interface SetCompanyStyleAction
{
    type: ESetCompanyAction.SET_COMPANY_STYLE;
    payload: IStyleDTO;
}
// Union of all action interfaces
type CompanyAction =
    SetCompanyNameAction
    | SetCompanyStyleAction

export const companyReducer = (state = initialCompanyState, action: CompanyAction): CompanyState =>
{
    switch (action.type)
    {
        case ESetCompanyAction.SET_COMPANY_NAME:
            return {
                ...state,
                name: action.payload
            }
        case ESetCompanyAction.SET_COMPANY_STYLE:
            return {
                ...state,
                style: action.payload
            }
        default:
            return state;
    }
};

// Action Creator for setting the company name
export const setCompanyName = (name: string): SetCompanyNameAction => ({
    type: ESetCompanyAction.SET_COMPANY_NAME,
    payload: name,
});

// Action Creator for setting company style
export const setCompanyStyle = (style: IStyleDTO): SetCompanyStyleAction => ({
    type: ESetCompanyAction.SET_COMPANY_STYLE,
    payload: style,
});