import React, { CSSProperties, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { setWindowSize } from "@store/reducer/windowSizeReducer";
import { DialogManager } from "src/sharedReact/Dialog/DialogManager";
import ErrorBoundary from "src/sharedReact/Error/ErrorBoundary";
import CookieBanner from "src/sharedReact/General/CookieBanner/CookieBanner";
import Layout from "src/sharedReact/Layouts/Layout/Layout";
import InnerLayout from "src/sharedReact/Layouts/InnerLayout/InnerLayout";
import FatalErrorPage from "@sharedReact/Error/FatalErrorPage";
import ErrorPage from "@sharedReact/Error/ErrorPage";
import LoggedInPage from "@src/Pages/LoggedInPage/LoggedInPage";
import LoginPage from "@src/Pages/LoginPage";
import NotAllowed from "@src/Pages/NotAllowed";
import NotFound from "@src/Pages/NotFound";
import { isLoggedIn } from "@src/Store/reducer/clientReducer";
import { logout } from "@src/Components/UserButton/UserButton";
import { CustomGraphQLError } from "@src/APIs/graphQl/graphQL";
import getWhoIAm from "@src/APIs/graphQl/Login/getWhoIAm";
import CompaniesPage from "@src/Pages/Company/CompaniesPage/CompaniesPage";
import CompanyPage from "@src/Pages/Company/CompanyPage/CompanyPage";

import { AppState } from "../Store/store";
import { ELinksAdmin } from "../globals";
import LoadingBox from "../sharedReact/General/LoadingBox/LoadingBox";




/**
 * App component.
 *
 * @returns {JSX.Element} The JSX element for the App component.
 */
function App()
{
  const dispatch = useDispatch();
  if (window.location.pathname === `/${ELinksAdmin.LOGGEDIN}`)
  {
    console.log("Clear Login App")
    const cookies = localStorage.getItem("cookies");
    localStorage.clear();
    cookies && localStorage.setItem("cookies", cookies);
  }
  const secret: string | null = useSelector((state: AppState) => state.client.secret);
  const actualStyle = useSelector((state: AppState) => state.company.style);

  useEffect(() =>
  {
    function handleResize()
    {
      dispatch(setWindowSize(window.innerWidth, window.innerHeight));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);



  React.useEffect(() =>
  {
    const themeColorDOM: HTMLMetaElement | null = window.document.querySelector('meta[name="theme-color"]')
    if (themeColorDOM)
    {
      themeColorDOM.content = actualStyle.primary;
    }
  }, [actualStyle.primary])




  const loadWhoIAm = () =>
  {
    if (isLoggedIn(secret))
    {
      getWhoIAm((me) =>
      {
        if (!me) return;
        // setStyle(me.style);
      }).catch((error: CustomGraphQLError) =>
      {
        console.error(error);
        if (error.statusCode === 401)
        {
          logout();
        }
      });
    }
  }

  useEffect(() =>
  {
    const intervalId = setInterval(() =>
    {
      loadWhoIAm();
    }, 2 * 60 * 60 * 1000);
    loadWhoIAm();
    return () =>
    {
      clearInterval(intervalId);
    };
  }, [dispatch, secret]);

  const MuiTheme = createTheme({
    palette: {
      primary: {
        main: actualStyle['primary'] ? actualStyle['primary'] : '#ff0000',
        contrastText: actualStyle['onPrimary'] ? actualStyle['onPrimary'] : '#ff0000',
      },
      secondary: {
        main: actualStyle['secondary'] ? actualStyle['secondary'] : '#00ffff',
        contrastText: actualStyle['onSecondary'] ? actualStyle['onSecondary'] : '#ff0000',

      },
    },
  });

  let secureRoutes = undefined
  if (isLoggedIn(secret))
  {
    secureRoutes = <React.Fragment>
      <Route index element={<CompaniesPage />} />
      <Route path={ELinksAdmin.COMPANIES} element={<CompaniesPage />} />
      <Route path={`${ELinksAdmin.COMPANIES}/:idString`} element={<CompanyPage />} />

      <Route path={`*`} element={<NotFound />} />
    </React.Fragment>
  }
  return (
    <ErrorBoundary fallback={(error) => <FatalErrorPage error={error} />}>
      <div
        id="themeContainer"
        style={
          {
            height: '100%',
            "--var-primary-color": actualStyle['primary'],
            "--var-secondary-color": actualStyle['secondary'],
            "--var-on-secondary-color": actualStyle['onSecondary'],
            "--var-on-primary-color": actualStyle['onPrimary'],
          } as CSSProperties
        }>
        <ThemeProvider theme={MuiTheme}>
          <BrowserRouter>
            <DialogManager>
              <CookieBanner />
              <Layout >
                <>
                  <ErrorBoundary fallback={(error) => <ErrorPage error={error} />}>
                    <div id="dialogContainer"></div>
                    <React.Suspense fallback={<InnerLayout>
                      <LoadingBox />
                    </InnerLayout>}>
                      <Routes>
                        <Route path={ELinksAdmin.LOGIN} element={<LoginPage />} />
                        <Route path={`${ELinksAdmin.LOGGEDIN}/*`} element={<LoggedInPage />} />
                        {secureRoutes}
                        <Route path={`*`} element={<NotAllowed />} />
                      </Routes>
                    </React.Suspense>
                  </ErrorBoundary>
                </>
              </Layout>
            </DialogManager>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;