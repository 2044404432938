import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InnerLayout from '@sharedReact/Layouts/InnerLayout/InnerLayout';
import store, { AppState } from '@store/store';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { setSecret } from '@store/reducer/clientReducer';
import './LoggedInPage.css';
import { ELinksAdmin } from '@src/globals';
import { useNavigate } from 'react-router-dom';
import GraphQL from '@src/APIs/graphQl/graphQL';

/**
 * Retrieves the error description from the hash.
 *
 * @returns {string} The error description.
 */
function getErrorDescriptionFromHash(): string | null
{
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const errorDescription = params.get('error_description');
    if (errorDescription)
    {
        return decodeURIComponent(errorDescription);
    }

    return null;
}

/**
 * Represents a component for displaying a logged-in page.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
function LoggedInPage()
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).loggedin;
    const [loginError, setLoginError] = React.useState(false as boolean);
    const error = getErrorDescriptionFromHash();

    useEffect(() =>
    {
        checkLogin().catch((ex) =>
        {
            console.log(ex)
            setLoginError(true);
        });
    }, [])

    if (error)
    {
        return <InnerLayout title={error}>
            <span>{error}</span>
        </InnerLayout>
    }

    return !loginError ?
        <InnerLayout title={langStrings.title}>
            <span>{langStrings.text}</span>
        </InnerLayout> :
        <InnerLayout title={langStrings.titleError}>
            <span>{langStrings.textError}</span>
        </InnerLayout>

    async function checkLogin()
    {
        console.log("CheckLogin");
        let idToken = "";
        const cookies = localStorage.getItem("cookies");
        localStorage.clear();
        cookies && localStorage.setItem("cookies", cookies);
        store.dispatch(setSecret(null))

        if (window.location.hash.length > 5)
        {
            const hashValues = window.location.hash.substr(1).split('&').map((s) =>
            {
                return s.split('=');
            });
            const accessToken = hashValues.find(s => s[0] === "access_token");
            const idTokenSplit = hashValues.find(s => s[0] === "id_token");
            if (accessToken && idTokenSplit) // New ID TOken
            {
                idToken = idTokenSplit[1];
                store.dispatch(setSecret(idToken))

                GraphQL.client = GraphQL.createClient()


                // window.location.pathname = `/${ELinksAdmin.COMPANIES}`;
                window.location.hash = '';
                navigate(`/${ELinksAdmin.COMPANIES}`);
            }
        }
    }
}


export default LoggedInPage;
