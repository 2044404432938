import React, { useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Root, createRoot } from 'react-dom/client';
import { SentimentVeryDissatisfied } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import store, { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';

import Dialog from '../Dialog/Dialog';

interface ErrorModalProps
{
    error: Error;
    errorMessage?: string;
    resolve: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ errorMessage, error, resolve }) =>
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).error;
    const offline = useSelector((state: AppState) => state.client.offline);

    const [isOpen, setIsOpen] = useState(true);
    const [expandError, setExpandError] = useState(false);

    const onConfirm = () =>
    {
        resolve();
        setIsOpen(false);
    };

    if (!isOpen) return null;

    let message = error.message;
    let title = langStrings.errorHappend;
    if (offline)
    {
        message = langStrings.offlineMessage;
        title = langStrings.offlineTitle;
    }
    if (errorMessage)
    {
        message = errorMessage;
    }
    return (
        <Dialog
            footer={
                <Button
                    text={langStrings.oftenUsed.okay}
                    icon={<SentimentVeryDissatisfied />}
                    onClick={onConfirm}
                />
            }
            title={title}
            onClose={onConfirm}
        >
            <p>{message}</p>
            <Typography
                variant='caption'
                style={{ cursor: 'pointer' }}
                sx={{ color: 'text.secondary' }}
                onClick={() => { setExpandError(!expandError) }}
            >
                {langStrings.errorDetails}
            </Typography>
            {
                expandError &&
                <>
                    <div>
                        <Typography variant='body2'>{langStrings.errorName}: {error.name}</Typography>
                        <Typography variant='body2'>{langStrings.errorMessage}: {error.message}</Typography>
                        {error.stack && (
                            <Typography variant='body2'>{langStrings.stackTrace}:</Typography>
                        )}
                        {error.stack && (
                            <Typography variant='body2' style={{ whiteSpace: 'pre-wrap' }}>
                                {error.stack}
                            </Typography>
                        )}
                    </div>
                    <pre>{JSON.stringify(error, null, 5)}</pre>
                </>

            }
        </Dialog>
    );
};


/**
 * Show error dialog.
 * 
 * @param {Error} error - The error.
 * @param {string} errorMessage - The error message.
 * @returns {Promise<void>} Returns a promise that resolves with void.
 */
export function showErrorDialog(error: Error, errorMessage?: string): Promise<void>
{
    const modalRoot = document.getElementById('dialogContainer');
    if (!modalRoot) throw new Error("modal-root not found");

    const newElement = document.createElement('div');
    modalRoot.appendChild(newElement);
    const root: Root = createRoot(newElement);

    return new Promise((resolve) =>
    {
        root.render(
            <Provider store={store}>
                <ErrorModal
                    error={error}
                    errorMessage={errorMessage}
                    resolve={() =>
                    {
                        root.unmount();
                        modalRoot.removeChild(newElement);
                        resolve();
                    }}
                />
            </Provider>
        );
    });
}
