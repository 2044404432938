import React, { useEffect } from "react";
import { getLanguageTexts } from "@sharedInterfaces/Language/languageHelper";
import InnerLayout from "@sharedReact/Layouts/InnerLayout/InnerLayout";
import { AppState } from "@store/store";
import { useSelector } from "react-redux";
import getCompanies from "@src/APIs/graphQl/Company/getCompanies";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ICompanyAdmin from "@sharedInterfaces/admin/ICompanyAdmin";
import { useNavigate } from "react-router-dom";
import { ELinksAdmin } from "@src/globals";
import { showErrorDialog } from "@sharedReact/Dialog/Dialogs/ErrorDialog/ErrorDialog";

export default function CompaniesPage()
{
    const navigate = useNavigate();
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).layout.oftenUsed;

    const selectedStage = useSelector((state: AppState) => state.general.selectedStage);

    const [companies, setCompanies] = React.useState<ICompanyAdmin[]>([]);

    useEffect(
        () =>
        {
            if (!selectedStage) return;
            getCompanies(selectedStage)
                .then((companies) =>
                {
                    setCompanies(companies);
                })
                .catch(showErrorDialog);
        },
        [selectedStage]
    )

    const toCompany = (companyId: string) =>
    {
        navigate(`/${ELinksAdmin.COMPANY}${companyId}`);
    }

    return (
        <InnerLayout title={langStrings.companies}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>CompanyId</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Employees</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map((company, index) =>
                    {
                        return (
                            <TableRow key={index}>
                                <TableCell
                                    style={{ cursor: 'pointer' }}
                                    onClick={toCompany.bind(null, company.companyId)}>
                                    {company.companyId}
                                </TableCell >
                                <TableCell >{company.title}</TableCell >
                                <TableCell >{company.employees}</TableCell >
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </InnerLayout>
    );
}