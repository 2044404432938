import React from 'react';
import './HelpDialog.css';
import { Close, Help } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { EHelpId } from '@sharedInterfaces/Language/languageTexts/helpDialog';
import { AppState } from '@store/store';
import Button from '@sharedReact/General/Button/Button';

import Dialog from '../Dialog/Dialog';
interface HelpDialogProps
{
    helpId: EHelpId;
}

/**
 * HelpDialog component.
 *
 * @param {HelpDialogProps} props - The properties for the HelpDialog.
 * @returns {JSX.Element} The rendered HelpDialog component.
 */
function HelpDialog(props: HelpDialogProps)
{
    const lang = useSelector((state: AppState) => state.employee.language);
    const langStrings = getLanguageTexts(lang).helpDialog;

    const [open, setOpen] = React.useState(false as boolean);

    return (
        <React.Fragment>
            <div className='helpButton' onClick={() => { setOpen(!open) }}>
                <Help />
            </div>
            {open && <Dialog title={langStrings.dialogs[props.helpId].title}
                onClose={function (): void
                {
                    setOpen(false);
                }}
                footer={
                    <div style={{ float: 'right' }}>
                        <Button
                            icon={<Close />}
                            text={langStrings.close}
                            size={'normal'}
                            onClick={async function (): Promise<void>
                            {
                                setOpen(false);
                            }} />
                    </div>
                }
            >
                {
                    langStrings.dialogs[props.helpId].texts.map(text => <p key={text}>{text}</p>)
                }
            </Dialog>}
        </React.Fragment>

    );
}

export default HelpDialog;
