import { ELanguage } from "../languageHelper"

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface IProductsTexts extends ITextBase
{
    suggestedEmployees: string
    descriptionPlaceholder: string | undefined
    empty: string
    skillCoverage: string
    edit: string
    delete: string
    fittingUsers: string
    employees: string
    skills: string
    certificates: string
    create: string
    productName: string
    description: string
    newProduct: string
    products: string
    newProductError: string
    save: string

}
type ILangData = {
    [lang in ELanguage]: IProductsTexts
}
export const productsTexts: ILangData = {
            'th': {
                suggestedEmployees: 'พนักงานที่แนะนำ',
        descriptionPlaceholder: 'การช่วยเหลือขาย:\nสิ่งที่ทำให้ผลิตภัณฑ์โดดเด่น ที่สำคัญใช้มากที่สุด กำหนดไว้ ผลิตภัณฑ์ควรเป็นอย่างไรบริการที่รวมอยู่ และไม่รวมอยู่ ผลลัพธ์ที่สร้างให้ลูกค้า?',
                oftenUsed: oftenUsedTranslations['th'],
                products: oftenUsedTranslations['th'].products,
                newProduct: 'ผลิตภัณฑ์ใหม่',
                productName: 'ชื่อสินค้า',
                description: oftenUsedTranslations['th'].description,
                create: oftenUsedTranslations['th'].create,
                skills: oftenUsedTranslations['th'].skills,
                certificates: oftenUsedTranslations['th'].certificates,
                newProductError: 'กรุณากรอกชื่อผลิตภัณฑ์',
                employees: oftenUsedTranslations['th'].employees,
                fittingUsers: 'ความสามารถที่ครอบคลุม',
                edit: oftenUsedTranslations['th'].edit,
                delete: oftenUsedTranslations['th'].delete,
                save: oftenUsedTranslations['th'].save,
                skillCoverage: 'ความสามารถที่ครอบคลุม',
                empty: oftenUsedTranslations['th'].empty,
            },
            'en': {
                suggestedEmployees: 'Suggested Employees',
        descriptionPlaceholder: 'Sales support:\nWhat distinguishes the product, where is it primarily needed, what requirements should be met, which services are included and which are not, what added value does it create for the customer?',
                oftenUsed: oftenUsedTranslations['en'],
                products: oftenUsedTranslations['en'].products,
                newProduct: 'New product',
                productName: 'Productname',
                description: oftenUsedTranslations['en'].description,
                create: oftenUsedTranslations['en'].create,
                skills: oftenUsedTranslations['en'].skills,
                certificates: oftenUsedTranslations['en'].certificates,
                newProductError: 'Please enter a product name.',
                employees: oftenUsedTranslations['en'].employees,
                fittingUsers: 'Skill coverage',
                edit: oftenUsedTranslations['en'].edit,
                delete: oftenUsedTranslations['en'].delete,
                save: oftenUsedTranslations['en'].save,
                skillCoverage: 'Skill coverage',
                empty: oftenUsedTranslations['en'].empty,

            },
            'de': {
                oftenUsed: oftenUsedTranslations['de'],
                products: oftenUsedTranslations['de'].products,
                newProduct: 'Neues Produkt',
                productName: 'Produktname',
                description: oftenUsedTranslations['de'].description,
                create: oftenUsedTranslations['de'].create,
                skills: oftenUsedTranslations['de'].skills,
                certificates: oftenUsedTranslations['de'].certificates,
                newProductError: 'Bitte gebe einen Produktnamen ein.',
                employees: oftenUsedTranslations['de'].employees,
                fittingUsers: 'Produktabdeckung',
                edit: oftenUsedTranslations['de'].edit,
                delete: oftenUsedTranslations['de'].delete,
                save: oftenUsedTranslations['de'].save,
                skillCoverage: 'Produktabdeckung',
                empty: oftenUsedTranslations['de'].empty,
                descriptionPlaceholder: 'Vertriebshilfe:\nWas zeichnet das Produkt aus, wo wird dieses maßgeblich benötigt, welche Voraussetzungen sollten erfüllt sein, welche Leistungen sind enthalten, welche nicht, welchen Mehrwert erzeugt es beim Kunden?',
                suggestedEmployees: 'Vorgeschlagene Mitarbeiter',
            },
        }