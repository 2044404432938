import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Box, List } from '@mui/material';
import { getLanguageTexts } from '@sharedInterfaces/Language/languageHelper';
import { AppState } from '@store/store';
import './Navigation.css';
import { ELinksAdmin } from '@src/globals';
import { setSelectedStage } from '@store/reducer/generalReducer';
import RowElement from '@sharedReact/General/Forms/RowElement/RowElement';

import { NavigationItem } from './NavigationItem';

interface NavigationProps
{
  // children: React.ReactNode;
  loggedIn: boolean;
  isOpen: boolean;
  fixedNav: boolean;
  setNavIsOpen: (open: boolean) => void
}

/**
 * Navigation component.
 *
 * @param {NavigationProps} props - The props for the navigation component.
 * @returns {JSX.Element} - The rendered navigation component.
 */
function Navigation(props: NavigationProps)
{
  const dispatch = useDispatch();
  const lang = useSelector((state: AppState) => state.employee.language);
  const langStrings = getLanguageTexts(lang).navigation;

  const stages = useSelector((state: AppState) => state.general.stages);
  const selectedStage = useSelector((state: AppState) => state.general.selectedStage);

  if (!props.loggedIn) return (null);

  const onClick = async () =>
  {
    if (props.fixedNav)
      props.setNavIsOpen(false);
  }

  return (
    <div className={'navigation' + (props.isOpen ? ' navIsOpen' : ' navIsClosed')}>
      <Drawer
        variant={props.fixedNav ? 'temporary' : 'persistent'}
        open={props.isOpen}
        container={document.querySelector('.navigation')}
        transitionDuration={{
          appear: 1000,
          enter: 500,
          exit: 0
        }}
        // hideBackdrop={true}
        onClose={() => props.setNavIsOpen(false)}
        sx={{
          width: 'var(--nav-size)',
          top: props.fixedNav ? 'var(--var-header-height)' : 0,
          flexShrink: 0,
          height: '100%',
          [`& .MuiDrawer-paper`]: {
            position: 'relative',
            width: 'var(--nav-size)',

          },
        }}
      // onClose={() => props.setNavIsOpen(false)}
      // onOpen={() => props.setNavIsOpen(true)}
      >
        <Box sx={{ overflow: 'auto' }}>
          <RowElement title={langStrings.stage}>
            <select
              value={selectedStage ? selectedStage : ''}
              style={{ width: '100%' }}
              onChange={(e) => dispatch(setSelectedStage(e.target.value))}
            >
              {!selectedStage && <option value={''} disabled>-</option>}
              {
                stages.map((stage) =>
                  <option key={stage} value={stage}>{stage}</option>
                )
              }
            </select>
          </RowElement>
          {selectedStage && <List>
            <NavigationItem onClick={onClick} path={`/${ELinksAdmin.COMPANIES}`} shortPath={`/${ELinksAdmin.COMPANIES}`} title={langStrings.companies}
              fixedNav={props.fixedNav}
            />
          </List>}
        </Box>
      </Drawer>
    </div >
  )
}
export default Navigation;
