import { gql } from "@apollo/client";
import IAdminWhoIAm from "@sharedInterfaces/admin/IAdminWhoIAm";
import store from "@store/store";
import { setStages } from "@store/reducer/generalReducer";

import GraphQL from "../graphQL";


const q = gql`
query {
    whoIAm {
        stages
        user {
            employeeId,
            companyId,
            email,
        },
    },
}
`;

/**
 * Retrieves the information about who I am.
 *
 * @async
 * @function getWhoIAm
 * @param {Function} callback - The callback function to be called with the retrieved information.
 * The callback function should accept a parameter of type MySelf.
 * (MySelf: MySelf => void
 * @returns {Promise<{ whoIAm: IMySelf; }>}
 *          A promise that resolves with an object containing the information of who I am,
 *          with the property "whoIAm" of type IMySelf.
 */
export default async function getWhoIAm(callback: (data: IAdminWhoIAm | null) => void)
{
    const variables = {
    };
    return GraphQL.query<{ whoIAm: IAdminWhoIAm }>(
        q,
        variables,
        (obj) =>
        {
            if (obj && obj.whoIAm)
            {
                store.dispatch(setStages(obj.whoIAm.stages));
                return callback(obj.whoIAm)
            }
            callback(null)
        }
    );
}