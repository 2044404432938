import React, { useRef } from 'react';
import './Dialog.css';
import { Close } from '@mui/icons-material';
import MuiDialog from "@mui/material/Dialog"
import { EHelpId } from '@sharedInterfaces/Language/languageTexts/helpDialog';

import HelpDialog from '../HelpDialog/HelpDialog';

interface DialogProps
{
    children: React.ReactNode
    className?: string
    footer?: React.ReactNode
    title: string;
    onClose: () => void;
    helpId?: EHelpId;
}

/**
 * Renders a dialog.
 *
 * @param {DialogProps} props - The dialog properties.
 * @returns {JSX.Element}
 */
function Dialog(props: DialogProps)
{
    const modalBodyRef = useRef<HTMLDivElement>(null);

    return (
        <MuiDialog maxWidth="lg" style={{ zIndex: 1302 }} className={props.className} onClose={props.onClose}
            container={() => document.getElementById('dialogContainer')}
            open={true}  >
            <div className="modal-header">
                <span className='topRightButtons'>
                    <span className="close" onClick={props.onClose}><Close /></span>
                    {props.helpId ? <span className='help'> <HelpDialog helpId={props.helpId} /></span> : ''}
                </span>
                <h2 className="title">{props.title}</h2>
            </div>
            <div className="modal-body" ref={modalBodyRef}>
                {props.children}
            </div>
            <div className="modal-footer">
                {props.footer}
            </div>

        </MuiDialog >
    );
}

export default Dialog;

