import { ELanguage, findMatchingLanguage } from "@sharedInterfaces/Language/languageHelper";
import { EGender } from "@sharedInterfaces/IEmployee";
export interface EmployeeState
{
    id: number
    companyId: string
    firstName: string
    lastName: string
    gender: EGender
    email: string
    pictureId: string | null
    version: number
    language: ELanguage;
}

export const initialEmployeeState: EmployeeState = {
    id: -1,
    companyId: '',
    firstName: "",
    lastName: "",
    gender: EGender.OTHER,
    email: "",
    language: findMatchingLanguage(window.navigator.languages),
    pictureId: null,
    version: 0,
};
export enum EmployeeActionTypes
{
    SET_EMPLOYEE = 'SET_EMPLOYEE',
    SET_EMPLOYEE_LANG = 'SET_EMPLOYEE_LANG',
    SET_EMPLOYEE_PICTURE_ID = 'SET_EMPLOYEE_PICTURE_ID',
}

// Spezifische Aktionstypen
interface ISetEmployeeAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE;
    payload: EmployeeState;
}

interface ISetEmployeeLangAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_LANG;
    payload: ELanguage;
}

interface ISetEmployeePictureIdAction
{
    type: EmployeeActionTypes.SET_EMPLOYEE_PICTURE_ID;
    payload: string | null;
}

// Kombinierter Aktionstyp
export type EmployeeAction =
    | ISetEmployeeAction
    | ISetEmployeeLangAction
    | ISetEmployeePictureIdAction
    ;

export const employeeReducer = (state = initialEmployeeState, action: EmployeeAction): EmployeeState =>
{
    switch (action.type)
    {
        case EmployeeActionTypes.SET_EMPLOYEE:
            return {
                ...state,
                ...action.payload,
            };
        case EmployeeActionTypes.SET_EMPLOYEE_LANG:
            return {
                ...state,
                language: action.payload
            };
        case EmployeeActionTypes.SET_EMPLOYEE_PICTURE_ID:
            return {
                ...state,
                pictureId: action.payload
            }
        default:
            return state;
    }
};


// SET_EMPLOYEE
export const setEmployeeStore = (state: EmployeeState): ISetEmployeeAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE,
    payload: state,
});

// Action Creator for setting employee
export const setEmployee = (employeeState: EmployeeState): ISetEmployeeAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE,
    payload: employeeState,
});

// Action Creator for setting employee language
export const setEmployeeLang = (language: ELanguage): ISetEmployeeLangAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_LANG,
    payload: language,
});

// Action Creator for setting employee picture ID
export const setEmployeePictureId = (pictureId: string | null): ISetEmployeePictureIdAction => ({
    type: EmployeeActionTypes.SET_EMPLOYEE_PICTURE_ID,
    payload: pictureId,
});