import { ELanguage } from "../languageHelper";

import { ITextBase, oftenUsedTranslations } from "./oftenUsed"

export interface ISkillCategoriesTexts extends ITextBase
{
    subCategories: string;
    mainLevel: string;
    parentCategory: string;
    employeeCount: string;
    skills: string;
    delete: string;
    create: string;
    categorize: string;
    newSkillCategoryError: string;
    newSkillCategory: string;
    save: string;
    skillcategoryName: string;
    description: string;
    count: string
    editCategory: string
    skillCategories: string
    empty: string
}
type ILangData = {
    [lang in ELanguage]: ISkillCategoriesTexts
}
export const skillcategoriesTexts: ILangData = {
    'th': {
        subCategories: 'หมวดหมู่ย่อย',
        mainLevel: 'ระดับหลัก',
        parentCategory: 'หมวดหมู่หลัก',
        employeeCount: 'จำนวนพนักงาน',
        categorize: 'จัดประเภท',
        editCategory: 'แก้ไขหมวดทักษะ',
        newSkillCategoryError: 'ข้อผิดพลาดในการบันทึก',
        skillcategoryName: 'ชื่อหมวดหมู่ทักษะ',
        newSkillCategory: 'หมวดหมู่ทักษะใหม่',
        count: oftenUsedTranslations['th'].count,
        skillCategories: oftenUsedTranslations['th'].skillCategories,
        empty: oftenUsedTranslations['th'].empty,
        create: oftenUsedTranslations['th'].create,
        save: oftenUsedTranslations['th'].save,
        description: oftenUsedTranslations['th'].description,
        delete: oftenUsedTranslations['th'].delete,
        skills: oftenUsedTranslations['th'].skills,
        oftenUsed: oftenUsedTranslations['th'],
    },
    'en': {
        subCategories: 'Subcategories',
        mainLevel: 'Main Level',
        parentCategory: 'Parent Category',
        employeeCount: 'Number of employees',
        categorize: 'Categorize',
        editCategory: 'Edit skill category',
        newSkillCategoryError: 'Error saving',
        skillcategoryName: 'Skill Category Name',
        newSkillCategory: 'New Skill Category',
        count: oftenUsedTranslations['en'].count,
        skillCategories: oftenUsedTranslations['en'].skillCategories,
        empty: oftenUsedTranslations['en'].empty,
        create: oftenUsedTranslations['en'].create,
        save: oftenUsedTranslations['en'].save,
        description: oftenUsedTranslations['en'].description,
        delete: oftenUsedTranslations['en'].delete,
        skills: oftenUsedTranslations['en'].skills,
        oftenUsed: oftenUsedTranslations['en'],
    },
    'de': {
        oftenUsed: oftenUsedTranslations['de'],
        skillCategories: oftenUsedTranslations['de'].skillCategories,
        empty: oftenUsedTranslations['de'].empty,
        count: oftenUsedTranslations['de'].count,
        create: oftenUsedTranslations['de'].create,
        save: oftenUsedTranslations['de'].save,
        description: oftenUsedTranslations['de'].description,
        delete: oftenUsedTranslations['de'].delete,
        skills: oftenUsedTranslations['de'].skills,
        categorize: "Kategorisieren",
        editCategory: 'Kategorie bearbeiten',
        newSkillCategoryError: 'Fehler beim Speichern',
        skillcategoryName: 'Skill-Kategorie-Name',
        newSkillCategory: 'Neue Skill-Kategorie',
        employeeCount: 'Mitarbeiteranzahl',
        parentCategory: 'Übergeordnete Kategorie',
        mainLevel: 'Hauptebene',
        subCategories: 'Untergeordnete Kategorien',
    },
}