import React, { ReactNode, useState } from "react";


interface DialogManagerContextProps
{
    openDialog: (dialog: React.ReactElement) => void;
}

export const DialogManagerContext = React.createContext<DialogManagerContextProps>({
    openDialog: () => { throw new Error('No DialogManager provider'); },
});

interface DialogManagerProps
{
    children: ReactNode
}
export const DialogManager: React.FC<DialogManagerProps> = ({ children }: DialogManagerProps) =>
{
    const [dialogs, setDialogs] = useState<React.ReactElement[]>([]);

    const openDialog = (dialog: React.ReactElement) =>
    {
        const existingResolve = dialog.props.resolve;
        const wrappedResolve = (value: unknown) =>
        {
            existingResolve && existingResolve(value);
            closeDialog(dialogs.length);
        };
        const dialogWithResolve = React.cloneElement(dialog, { resolve: wrappedResolve });
        setDialogs([...dialogs, dialogWithResolve]);
    };
    const closeDialog = (index: number) =>
    {
        setDialogs(dialogs.filter((_, i) => i !== index));
    };


    return (
        <DialogManagerContext.Provider value={{ openDialog }}>
            {children}
            {dialogs.map((dialog, index) => React.cloneElement(dialog, {
                key: index,
            }))}
        </DialogManagerContext.Provider>
    );
};