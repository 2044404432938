import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Dialog, Typography } from '@mui/material';
import { generateFullName } from '@sharedInterfaces/globalHelper';
import { AppState } from '@store/store';

import styles from './EmployeePicture.module.css';
/**
 * Generates the JSX element for the employee picture.
 *
 * @param {Object} param - The parameter object.
 * @param {number} param.avatarSize - The size of the avatar. Default value is 38.
 * @param {import(".../Employee").default} param.employee - The employee object.
 *
 * @returns {JSX.Element} The JSX element for the employee picture.
 */
export function EmployeePicture({ avatarSize = 38, employee, openDialogOnClick }:
    {
        avatarSize: number;
        openDialogOnClick?: boolean,
        employee: { firstName: string; lastName: string; pictureId: string | null; id: number; companyId: string };
    })
{
    const width = useSelector((state: AppState) => state.windowSize.width);
    if (width < 500 && avatarSize > 50) avatarSize = 50;
    const [imageLoaded1, setImageLoaded1] = useState<boolean>(false);
    const [imageLoaded2, setImageLoaded2] = useState<boolean>(false);
    const [imageLoaded1000, setImageLoaded1000] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState(false);

    const nameShort = (employee.firstName ? employee.firstName[0] : '') + (employee.lastName ? employee.lastName[0] : '');

    const normalContent = <>
        {employee.pictureId && <div className={styles.normalContent}>
            {avatarSize > 38 &&
                <img
                    src={`/static/profilepictures/${employee.companyId}/${employee.id}-100-${employee.pictureId}.png`}
                    width={avatarSize}
                    height={avatarSize}
                    onLoad={setImageLoaded1.bind(null, true)}
                    style={{
                        display: imageLoaded1 ? 'block' : 'none',
                    }} />
            }
            <img
                src={`/static/profilepictures/${employee.companyId}/${employee.id}-38-${employee.pictureId}.png`}
                width={avatarSize}
                height={avatarSize}
                onLoad={setImageLoaded2.bind(null, true)}
                style={{
                    display: imageLoaded2 && !imageLoaded1 ? 'block' : 'none',
                }} />
            {(!imageLoaded1 && !imageLoaded2) &&
                nameShort
            }
        </div>}
        {!employee.pictureId &&
            <div className={styles.normalContent}>
                {nameShort}
            </div>
        }
    </>


    const onClick = () =>
    {
        if (openDialogOnClick) return setShowDialog(true);
    }

    const avatar = <Avatar
        onClick={onClick}
        alt={generateFullName(employee.firstName, employee.lastName)}
        title={generateFullName(employee.firstName, employee.lastName)}
        sx={{
            width: avatarSize,
            height: avatarSize,
            fontSize: avatarSize < 100 ? 16 : 32,
            lineHeight: `${avatarSize}px`,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'var(--var-secondary-color)',
            color: 'var(--var-on-secondary-color)',
            padding: '0px', // Adjust padding as needed
        }}
    >
        {normalContent}
    </Avatar >;

    return (
        <>
            <div className={styles.employeePicture}>
                {
                    avatar
                }
            </div>
            <Dialog
                style={{ zIndex: 99999 }}
                open={showDialog}
                onClose={setShowDialog.bind(null, false)}>
                <img
                    src={`/static/profilepictures/${employee.companyId}/${employee.id}-1000-${employee.pictureId}.png`}
                    alt={generateFullName(employee.firstName, employee.lastName)}
                    title={generateFullName(employee.firstName, employee.lastName)}
                    style={{
                        width: '100%',
                        height: 'auto',
                        display: imageLoaded1000 ? 'block' : 'none',
                    }}
                    onLoad={setImageLoaded1000.bind(null, true)}
                />
                <img
                    src={`/static/profilepictures/${employee.companyId}/${employee.id}-100-${employee.pictureId}.png`}
                    alt={generateFullName(employee.firstName, employee.lastName)}
                    title={generateFullName(employee.firstName, employee.lastName)}
                    style={{
                        width: '100%',
                        height: 'auto',
                        display: !imageLoaded1000 ? 'block' : 'none',
                    }}
                />
                <Typography variant="h4" align="center">
                    {`${employee.firstName} ${employee.lastName}`}
                </Typography>
            </Dialog>
        </>
    );
}

