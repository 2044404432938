import { ReactNode, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { LinearProgress, Typography } from '@mui/material';
import { AppState } from '@store/store';

import style from './ContentHeader.module.css';

interface ContentHeaderProps
{
    title: React.ReactNode
    inDialog?: boolean;
    bigRightBox?: JSX.Element
    buttons?: ReactNode
    styleType?: 'grid' | 'flex'
    children?: ReactNode
    link?: string
    tabs?: ITabConfig[]
}

/**
 * Component for displaying the header of a content section.
 *
 * @param {ContentHeaderProps} props - The props object for configuring the content header.
 * @returns {JSX.Element} - The JSX element representing the content header.
 */
function ContentHeader(props: ContentHeaderProps)
{
    const offline = useSelector((state: AppState) => state.client.offline)
    const loading = useSelector((state: AppState) => state.client.loading)
    const styleType = props.styleType ? props.styleType : 'grid'
    const navigate = useNavigate();
    const onClickTitle = () =>
    {
        if (!props.link) return
        navigate(props.link);
    }

    return (
        <>
            <div className={style.contentHeader} style={{ marginTop: props.inDialog ? 0 : undefined }}>
                {props.bigRightBox && <div>
                    {props.bigRightBox}
                </div>
                }
                <div style={{ width: '100%' }}>
                    <div className={style.contentHead}>
                        {props.title &&
                            <div className={style.contentTitle} style={{ cursor: props.inDialog ? 'pointer' : undefined }} onClick={props.inDialog ? onClickTitle : undefined}>
                                {/* <Typography variant='h4'> */}
                                {props.title}
                                {/* </Typography> */}
                            </div>}
                        {props.buttons && <div className={style.contentButtons}>
                            {props.buttons}
                        </div>}
                    </div>
                    {props.children && (
                        <>
                            <div className={style.divideline}> </div>
                            <div className={styleType === 'flex' ? style.informationsFlex : style.informationsGrid}>{props.children}</div>
                        </>
                    )}
                </div>
            </div >
            {
                props.tabs &&
                <TabContainer tabs={props.tabs} />
            }
            {
                (loading && !props.inDialog) && <LoadingBar />

            }
            {
                offline &&
                <>
                    <div className={style.divideline}> </div>
                    <div
                        className={style.offline}
                    >
                        <Typography typography='subtitle2'>
                            Offline!
                        </Typography>
                    </div>
                </>
            }
        </>
    );
}

export default ContentHeader;


/**
 * Represents a loading bar component.
 *
 * @returns {JSX.Element} The loading bar component.
 */
function LoadingBar()
{
    const CONST_MAX_LOADING = 98;
    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);

    const [show, setShow] = useState(false);

    useEffect(() =>
    {
        setTimeout(() =>
        {
            setShow(true);
        }, 100);
    }, [])

    const progressRef = React.useRef(() => { });
    React.useEffect(() =>
    {
        progressRef.current = () =>
        {
            if (progress > CONST_MAX_LOADING)
            {
                setProgress(CONST_MAX_LOADING);
                setBuffer(99);
            }
            else if (progress < CONST_MAX_LOADING * 0.25)
            {
                const diff2 = Math.abs(Math.random() * 20);
                setProgress(progress + 10);
                setBuffer(progress + diff2);
            }
            else if (progress < CONST_MAX_LOADING * 0.5)
            {
                const diff2 = Math.abs(Math.random() * 20);
                setProgress(progress + 2);
                setBuffer(progress + diff2);
            }
            else if (progress < CONST_MAX_LOADING * 0.7)
            {
                const diff2 = Math.abs(Math.random() * 10);
                setProgress(progress + 0.75);
                setBuffer(progress + diff2);
            }
            else if (progress < CONST_MAX_LOADING)
            {
                const diff2 = Math.abs(Math.random() * 5);
                setProgress(progress + 0.2);
                setBuffer(progress + diff2);
            }
        };
    });

    React.useEffect(() =>
    {
        const timer = setInterval(() =>
        {
            progressRef.current();
        }, 50);

        return () =>
        {
            clearInterval(timer);
        };
    }, []);


    if (!show) return <></>

    return <>
        <div className={style.divideline}> </div>
        <div
            className={style.loading}
        >
            <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
        </div>
    </>
}


export interface ITabConfig
{
    title: string
    link: string
}


/**
 * Represents a tab container component.
 * 
 * @param {object[]} tabs - An array of tab configurations.
 * @param {string} tabs[].title - The title of the tab.
 * @param {string} tabs[].content - The content of the tab.
 * 
 * @returns {JSX.Element} - The rendered tab container.
 */
function TabContainer({ tabs }: { tabs: ITabConfig[] })
{
    const containerRef = useRef<HTMLDivElement>(null);
    const [visibleTabs, setVisibleTabs] = useState<ITabConfig[]>([]);
    const [hiddenTabs, setHiddenTabs] = useState<ITabConfig[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () =>
    {
        setAnchorEl(null);
    };
    const recalculateTabs = () =>
    {
        if (!containerRef.current) return;

        const containerWidth = containerRef.current.getBoundingClientRect().width;
        let usedWidth = 0;
        const newVisibleTabs: ITabConfig[] = [];
        const newHiddenTabs: ITabConfig[] = [];

        for (const tab of tabs)
        {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = tab.title;
            tempDiv.className = style.tabItem; // Apply your tab styles here
            containerRef.current.appendChild(tempDiv);
            const tabWidth = tempDiv.getBoundingClientRect().width;
            containerRef.current.removeChild(tempDiv);

            if (usedWidth + tabWidth <= containerWidth - 45) // 45px buffer
            {
                newVisibleTabs.push(tab);
                usedWidth += tabWidth;
            } else
            {
                newHiddenTabs.push(tab);
                const remainingTabs = tabs.slice(tabs.indexOf(tab) + 1);
                newHiddenTabs.push(...remainingTabs);
                break;
            }
        }

        setVisibleTabs(newVisibleTabs);
        setHiddenTabs(newHiddenTabs);
    };

    useEffect(() =>
    {
        recalculateTabs();
        // Listen for resize events
        const handleResize = () => recalculateTabs();
        window.addEventListener('resize', handleResize);
        return () =>
        {
            window.removeEventListener('resize', handleResize);
        };
    }, [tabs]);

    const noActiveTab = !tabs.map(t => t.link).includes(location.pathname);

    return (
        <div ref={containerRef} className={style.tabContainer}>
            {visibleTabs.map((t, index) => (
                <NavLink
                    key={t.link}
                    to={t.link}
                    className={({ isActive }) =>
                        (isActive || (noActiveTab && index === 0) ? style.active : '') +
                        ' ' +
                        style.tabItem
                    }
                >
                    {t.title}
                </NavLink>
            ))}
            {hiddenTabs.length > 0 && (
                <>
                    <IconButton style={{ marginLeft: 'auto' }} aria-label="more" onClick={handleClick}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {hiddenTabs.map((t, index) => (
                            <MenuItem onClick={handleClose} key={t.link}>
                                <NavLink to={t.link}
                                    className={({ isActive }) =>
                                        (isActive || (noActiveTab && index === 0) ? style.activeMenu : '') +
                                        ' ' +
                                        style.tabItemMenu
                                    }
                                >
                                    {t.title}
                                </NavLink>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
        </div>
    );
}