import { ELanguage } from "../languageHelper";

import { oftenUsedTranslations } from "./oftenUsed"

export interface ICategorizeDialogTexts
{
    moved: string;
    notMoved: string;
    explainWishTextSkills: string;
    defaultFindCategoryForSkills: string;
    editCategory: string;
    generate: string;
    explainWishText: string;
    pleaseEnterCategoryName: string;
    createNewCategory: string
    existingCategories: string;
    suggestCategoriesTitle: string;
    suggestCategoriesDescription: string;
    suggestCategorisationTitle: string;
    suggestCategorisationDescription: string;
    AiCardTitle: string;
    cancel: string
    addCategoryNameLabel: string
    addCategoryNamePlaceholder: string
    addCategory: string
    useAndClose: string
    yourAiWish: string;
    categorize: string;
    dialogTitle: string
    skillsWithoutCategory: string
    defaultCategorizeText: string
    use: string
    errorSaving: string
}
type ILangData = {
    [lang in ELanguage]: ICategorizeDialogTexts
}
export const categorizedialogTexts: ILangData = {
            'th': {
                notMoved: 'ยังไม่ได้ย้าย',
        moved: 'ย้ายแล้ว',
        explainWishTextSkills: 'ใช้ช่องนี้เพื่อให้คำแนะนำเฉพาะเจาะจงกับ AI สำหรับการจัดหมวดหมู่ทักษะของคุณ อธิบายว่าคุณอยากให้มีการจัดสรรทักษะอย่างไรหรือแชร์ความต้องการเฉพาะของคุณ เนื้อหาที่คุณให้มาจะช่วย AI จัดหมวดหมู่ทักษะได้ตรงตามที่คุณต้องการ',
                defaultFindCategoryForSkills: 'โปรดค้นหาหมวดหมู่ที่เหมาะสมที่สุดสำหรับทักษะแต่ละอย่าง',
                editCategory: 'แก้ไขหมวดหมู่',
                generate: 'สร้าง',
                explainWishText: 'เพื่อที่จะเสนอหมวดหมู่ที่ปรับให้เหมาะสมกับคุณ, ระบบ AI ไม่เพียงแต่วิเคราะห์หมวดหมู่ที่ได้กำหนดไว้ในระบบนี้อยู่แล้ว แต่ยังพิจารณาถึงความสามารถที่คุณมีและหมายเหตุเฉพาะของคุณอีกด้วย กรุณาแจ้งให้เราทราบเกี่ยวกับความคิดและความต้องการของคุณเกี่ยวกับการจัดระเบียบใหม่และการจัดหมวดหมู่ความสามารถของคุณ ข้อมูลจากคุณเป็นส่วนสำคัญที่จะช่วยให้ AI สามารถปรับข้อเสนอให้ตรงกับความต้องการของคุณได้อย่างดีที่สุด',
                pleaseEnterCategoryName: 'กรุณาใส่ชื่อประเภท',
                createNewCategory: 'สร้างหมวดหมู่ใหม่',
                existingCategories: 'หมวดหมู่ที่มีอยู่',
                suggestCategorisationDescription: 'ด้วยความช่วยเหลือของ AI คุณสามารถจัดเรียงความสามารถของคุณเข้าหมวดหมู่ที่สร้างขึ้นได้อย่างแม่นยำ ขั้นตอนนี้ทำให้การจัดสรรทักษะของคุณเข้ากับหมวดหมู่ที่เหมาะสมที่สุดง่ายขึ้น ทำให้การค้นหาและการหาทักษะเฉพาะกลายเป็นเรื่องง่าย ซึ่งเหมาะอย่างยิ่งสำหรับการจัดการและขยายรายการทักษะของคุณอย่างมีประสิทธิภาพ',
                suggestCategoriesDescription: 'ใช้พลังของ AI เพื่อสร้างโครงสร้างที่มีการคิดที่ละเอียดและมีประสิทธิภาพสำหรับทักษะของคุณ กระบวนการนี้จะช่วยคุณในการกำหนดหมวดหมู่และหมวดหมู่ย่อยที่ทำให้การจัดองค์กรทักษะของคุณเป็นไปอย่างชัดเจนและมีลอจิก โดยเหมาะอย่างยิ่งในการจัดกลุ่มทักษะของคุณให้เป็นระเบียบและสามารถค้นหาได้ง่าย',
                suggestCategorisationTitle: 'จัดหมวดหมู่ความสามารถ',
                suggestCategoriesTitle: 'สร้างและจัดโครงสร้างหมวดหมู่',
                addCategory: 'เพิ่มหมวดหมู่',
                addCategoryNamePlaceholder: 'ใส่ชื่อสำหรับหมวดหมู่',
                addCategoryNameLabel: 'ชื่อของหมวดหมู่ใหม่',
                dialogTitle: 'จัดประเภททักษะ',
                categorize: "จัดประเภท",
                yourAiWish: 'ความประสงค์ของคุณจาก AI',
                skillsWithoutCategory: 'ทักษะที่ไม่มีประเภท',
                defaultCategorizeText: 'โปรดแนะนำการจัดประเภท!',
                use: 'ใช้งาน',
                useAndClose: 'ใช้และปิด',
                errorSaving: 'เกิดข้อผิดพลาดในการบันทึก โปรดลองอีกครั้ง',
                AiCardTitle: 'การสนับสนุน AI',
                cancel: oftenUsedTranslations['th'].cancel,
            },
            'en': {
                notMoved: 'Not moved',
        moved: 'Moved',
        explainWishTextSkills: 'Use this space to give the AI specific instructions for categorizing your skills. Describe how you envision the allocation or share any special preferences. Your input helps the AI to assign the skills to categories exactly as you wish.',
                defaultFindCategoryForSkills: 'Please find the best category for each skill.',
                editCategory: 'Edit Category',
                generate: 'Generate',
                explainWishText: 'To offer you tailored category suggestions, the AI not only analyzes the categories already defined in this system but also considers your existing skills and specific remarks. Please let us know your ideas and wishes concerning the reorganization and categorization of your skills. Your inputs are crucial for the AI to tailor the suggestions to your needs optimally.',
                pleaseEnterCategoryName: 'Please enter a category name',
                createNewCategory: 'Create New Category',
                existingCategories: 'Existing Categories',
                suggestCategorisationDescription: 'With the help of AI, you can precisely place your skills into the created categories. This step simplifies the assignment of each skill to the most suitable category, making the search and finding of specific skills easier. Ideal for efficiently managing and expanding your repertoire of skills.',
                suggestCategoriesDescription: 'Harness the power of AI to create a thoughtful and efficient structure for your skills. This process helps you define categories and subcategories that facilitate clear and logical organization of your skills. Ideal for grouping your skills neatly and making them easily searchable.',
                suggestCategorisationTitle: 'Classify skills',
                suggestCategoriesTitle: 'Create & structure categories',
                addCategory: 'Add category',
                addCategoryNameLabel: 'Name of the new category',
                addCategoryNamePlaceholder: 'Enter a name for the category',
                dialogTitle: 'Categorize skills',
                categorize: "Categorize",
                yourAiWish: 'Your wish from the AI',
                skillsWithoutCategory: 'Skills without category',
                defaultCategorizeText: 'Please suggest a categorisation!',
                use: 'Apply',
                useAndClose: 'Use and Close',
                errorSaving: 'An error occurred while saving, please try again.',
                AiCardTitle: 'AI Support',
                cancel: oftenUsedTranslations['en'].cancel,
            },
            'de': {
                cancel: oftenUsedTranslations['de'].cancel,
                addCategory: 'Kategorie hinzufügen',
                addCategoryNameLabel: 'Name der neuen Kategorie',
                addCategoryNamePlaceholder: 'Gib einen Namen für die Kategorie ein',
                dialogTitle: 'Fähigkeiten kategorisieren',
                categorize: 'Kategorisieren',
                yourAiWish: 'Dein Wunsch an die KI',
                skillsWithoutCategory: 'Fähigkeiten ohne Kategorie',
                defaultCategorizeText: 'Bitte schlage mir eine Kategorisierung vor!',
                use: 'Anwenden',
                useAndClose: 'Anwenden und Schließen',
                errorSaving: 'Beim speichern ist ein Fehler aufgetreten, bitte versuche es erneut.',
                AiCardTitle: 'KI - Unterstützung',
                suggestCategoriesTitle: 'Kategorien erstellen & strukturieren',
                suggestCategorisationTitle: 'Fähigkeiten einordnen',
                suggestCategoriesDescription: 'Nutze die Kraft der KI, um eine durchdachte und effiziente Struktur für Deine Fähigkeiten zu erstellen. Dieser Prozess hilft Dir, Kategorien und Unterkategorien zu definieren, die eine klare und logische Organisation Deiner Fähigkeiten ermöglichen. Perfekt geeignet, um Deine Fähigkeiten übersichtlich zu gruppieren und schnell auffindbar zu machen.',
                suggestCategorisationDescription: 'Mit Hilfe von KI kannst Du Deine Fähigkeiten präzise in die erstellten Kategorien einordnen. Dieser Schritt erleichtert die Zuweisung jeder Fähigkeit zu der am besten passenden Kategorie, wodurch die Suche und das Auffinden von spezifischen Fähigkeiten vereinfacht werden. Ideal, um Dein Repertoire an Fähigkeiten effektiv zu managen und zu erweitern.',
                existingCategories: 'Vorhandene Kategorien',
                createNewCategory: 'Neue Kategorie erstellen',
                pleaseEnterCategoryName: 'Bitte gib einen Namen für die Kategorie ein',
                explainWishText: 'Um Dir maßgeschneiderte Kategorie-Vorschläge anzubieten, analysiert die KI nicht nur die bereits in diesem System definierten Kategorien, sondern berücksichtigt auch Deine vorhandenen Fähigkeiten und spezifischen Anmerkungen. Bitte teile uns mit, welche Vorstellungen und Wünsche Du bezüglich der Neuordnung und Kategorisierung Deiner Fähigkeiten hast. Deine Eingaben sind entscheidend, damit die KI die Vorschläge optimal auf Deine Bedürfnisse abstimmen kann.',
                generate: 'Generieren',
                editCategory: 'Kategorie bearbeiten',
                defaultFindCategoryForSkills: 'Bitte suche für jede Fähigkeit die beste Kategorie.',
                explainWishTextSkills: 'Nutze dieses Feld, um der KI spezifische Anweisungen für die Einsortierung Deiner Fähigkeiten zu geben. Beschreibe, wie Du Dir die Zuordnung vorstellst oder teile besondere Präferenzen mit. Dein Input unterstützt die KI dabei, die Fähigkeiten genau nach Deinen Wünschen den Kategorien zuzuweisen.',
                moved: 'Verschoben',
                notMoved: 'Nicht verschoben',
            },
        }